<div class="parallax row h-100">
  <div class="cl-md-6 container-sm my-auto">
    <div class="card-group">
      <div class="card">
        <div class="card-body">
          <h4 class="m-b-0">
            <span class="icon-text">Create a new profile for the pet</span>
          </h4>
          <form (ngSubmit)="createProfile()" [formGroup]="createProfileForm">
            <div class="row">
              <div class="col-md-7">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Full name of pet" formControlName="petName">
                  <div *ngIf="(controls.petName.touched||submitted) && controls.petName.errors"
                    class="text-danger text-left">
                    <small *ngIf="controls.petName.errors.required">Name is required.</small>
                  </div>
                </div>
                <div class="form-group">
                  <select class="form-control" (change)="onSelect()" formControlName="petGroup">
                    <option selected disabled value="-1"> Select a Group</option>
                    <option *ngFor="let group of groups" [value]="group?.id">
                      {{group.name}}
                    </option>
                  </select>
                  <div *ngIf="(controls.petGroup.touched||submitted) && controls.petGroup.errors"
                    class="text-danger text-left">
                    <small *ngIf="controls.petGroup.errors">Pet group is required.</small>
                  </div>
                </div>
                <div class="form-group">
                  <select class="form-control" formControlName="petBreed">
                    <option selected disabled value="-1"> Select a breed</option>
                    <option *ngFor="let breed of breeds" [value]="breed?.id">
                      {{breed.name}}
                    </option>
                  </select>
                  <div *ngIf="(controls.petBreed.touched||submitted) && controls.petBreed.errors"
                    class="text-danger text-left">
                    <small *ngIf="controls.petBreed.errors">Pet breed is required.</small>
                  </div>
                </div>
                <div class="form-group">
                  <input formControlName="dateOfBirth" type="date" class="form-control" placeholder="Date of birth">
                  <div *ngIf="(controls.dateOfBirth.touched||submitted) && controls.dateOfBirth.errors"
                    class="text-danger text-left">
                    <small *ngIf="controls.dateOfBirth.errors.required">Date of birth is required.</small>
                  </div>
                </div>
                <div class="form-group">
                  <div class=" form-check-group">
                    <label for="gender" class="from-inline control-left mr-sm-2">Gender: </label>
                    <div class="form-check form-check-inline">
                      <input formControlName="gender" type="radio" class="form-inline" placeholder="Gender" [value]=1
                        id="option1">
                      <label class="form-check-label" for="option1">Male</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input formControlName="gender" type="radio" class="form-inline" placeholder="Gender" [value]=2
                        id="option2">
                      <label class="form-check-label" for="option2">Female</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input formControlName="gender" type="radio" class="form-inline" placeholder="Gender" [value]=0
                        id="option3">
                      <label class="form-check-label" for="option3">Not Known</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input formControlName="gender" type="radio" class="form-inline" placeholder="Gender" [value]=9
                        id="option4">
                      <label class="form-check-label" for="option4">Not Applicable</label>
                    </div>
                  </div>
                  <div *ngIf="(controls.gender.touched||submitted) && controls.gender.errors"
                    class="text-danger text-left">
                    <small *ngIf="controls.gender.errors"> Gender is required. </small>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="text-center profile-pic">
                  <img [src]="imageSrc" class="img-fluid img-thumbnail">
                </div>
                <div class="caption">
                  <label for="profilePicture" class="btn btn-primary" role="button"> Select your Picture
                    <input type="file" id="profilePicture" accept='image/*' #profilePicture (change)="readUrl($event)"
                      hidden />
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <input formControlName="description" type="text" class="form-control"
                placeholder="Optional Description of your pet">
            </div>
            <button type="submit" class="btn btn-azure">
              <i *ngIf='showWait' class='fa fa-paw fa-spin'></i>Let's Rock
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
