import {Injectable} from '@angular/core';
import {API_URL} from '@pettly/shared/API_URL';
import {ApiRequestInfrastructure} from '../apiRequestInfrastructure';
import {DraftPost} from '@pettly/models/draftPost';
import {LinkPreview} from '@pettly/models/linkPreview';

@Injectable()
export class PostService {

  constructor(private apiRequest: ApiRequestInfrastructure) {
  }

  public async createPost(post: DraftPost, profileId: string): Promise<PostResponse> {
    const postData = {
      content: post.content,
      petAccountId: profileId,
      media: post.postMedia,
      linkPreview: post.linkPreview
    };
    const body = JSON.stringify(postData);
    const createdPost = await this.apiRequest.post<PostResponse>(API_URL.posts({petId: profileId}), body);
    createdPost.comments = [];
    createdPost.reactions = [];
    return createdPost;
  }

  public createComment(content: string, authorId: string, postId: string): Promise<Comment> {
    const postData = {content: content, petAccountId: authorId, postId: postId};
    const body = JSON.stringify(postData);
    return this.apiRequest.post<Comment>(API_URL.postComments({petId: authorId, postId}), body);
  }

  public createReaction(reactionType: number, postId: string, authorId: string): Promise<Reaction> {
    const postData = {reactionType: reactionType};
    const body = JSON.stringify(postData);
    return this.apiRequest.post<Reaction>(API_URL.postReactions({petId: authorId, postId}), body);
  }

  public removeReaction(reactionId: string, postId: string, authorId: string): Promise<void> {
    let uri = `${API_URL.postReactions({petId: authorId, postId})}/${reactionId}`;
    return this.apiRequest.delete(uri);
  }

  public getFeed(profileId: string, pageNumber: number, pageSize: number): Promise<PostResponse[]> {
    const url = `${API_URL.feeds({petId: profileId})}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.apiRequest.getAll<PostResponse>(url);
  }

  public getActivityFeed(profileId: string, pageNumber: number, pageSize: number): Promise<PostResponse[]> {
    const url = `${API_URL.feeds({petId: profileId})}/activities?&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.apiRequest.getAll<PostResponse>(url);
  }

  public hidePostFromTimeLine(postId: string, petId: string): Promise<void> {
    const url = `${API_URL.posts({petId})}/${postId}/hide`;
    return this.apiRequest.put(url, null);
  }

  public removePost(postId: string, petId: string): Promise<void> {
    const url = `${API_URL.posts({petId})}/${postId}`;
    return this.apiRequest.delete(url);
  }

  public createReactionOnComment(reactionType: number, postId: string, commentId: string, authorId: string): Promise<Reaction> {
    const postData = {reactionType: reactionType};
    const body = JSON.stringify(postData);
    return this.apiRequest.post<Reaction>(API_URL.postReactionOnComments({
      petId: authorId,
      postId,
      commentId
    }), body);
  }
}

export interface Author {
  id: string;
  name: string;
  profilePictureUri: string;
}

export interface Comment {
  content: string;
  id: string;
  createdAt: Date;
  author: Author;
  reactions: Array<Reaction>;
}

export interface Reaction {
  reactionType: number;
  id: string;
  createdAt: Date;
  author: Author;
}

export interface PostResponse {
  content: string;
  id: string;
  createdAt: Date;
  updatedAt: string;
  mediaUris: string[];
  author: Author;
  linkPreview: LinkPreview;
  comments: Array<Comment>;
  reactions: Array<Reaction>;
}

