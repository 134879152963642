import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {animate, group, query, style, transition, trigger} from '@angular/animations';
import {DeviceInfoService} from "@pettly/services/ui/device-info.service";

@Component({
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  animations: [
    trigger('flip', [
      transition('* => *', [
        style({position: 'relative'}),
        group([
          query(':self', [
            style({transform: 'rotateY(0deg)',}),
            animate('500ms ease-in', style({transform: 'rotateY(180deg)'}))
          ], {optional: true}),
          query(':leave', [
            animate('0ms 249ms', style({display: 'none', transform: 'none'}))
          ], {optional: true}),
          query(':enter', [
            style({display: 'none', transform: 'rotateY(180deg)'}),
            animate('240ms 250ms ease-out', style({display: 'block', transform: 'rotateY(180deg)'})),
          ], {optional: true}),
        ]),
      ]),
    ])]

})
export class AccountComponent implements OnInit {
  public accountPadding = "1em";

  constructor(private title: Title,
              private deviceInfo: DeviceInfoService
  ) {
    this.title.setTitle('Pettly: Introduce yourself to pets from all over the world');
  }

  async ngOnInit(): Promise<void> {
    await this.setCutoutPadding()
  }

  private async setCutoutPadding() {
    if (!await this.deviceInfo.hasNotch()) return;
    this.accountPadding = (await this.deviceInfo.getInsetTop() + 8).toString() + "px";
  }
}


