import { PipeTransform, Pipe } from '@angular/core';
import { PetProfile } from '@pettly/models/petProfile';

@Pipe({
    name: 'profileFilter'
})
export class ProfileFilterPipe implements PipeTransform {
    transform(value: PetProfile[], filterBy: string): PetProfile[] {
        filterBy = filterBy ? filterBy.toLocaleLowerCase() : null;
        return filterBy ? value.filter((petProfile: PetProfile) =>
            petProfile.name.toLocaleLowerCase().indexOf(filterBy) !== -1) : value;
    }
}
