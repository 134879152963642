import {Injectable} from '@angular/core';
import {ApiRequestInfrastructure} from '@pettly/services/apiRequestInfrastructure';
import {API_URL} from '@pettly/shared/API_URL';
import {LinkPreview} from '@pettly/models/linkPreview';

@Injectable()
export class LinkPreviewService {
  constructor(private apiRequest: ApiRequestInfrastructure) {
  }

  public linkPreviewFor(hyperlink: string): Promise<LinkPreview> {
    return this.apiRequest.get<LinkPreview>(API_URL.LINK_PREVIEW + hyperlink, null).catch();
  }
}
