<ng-container *ngIf="visible">
  <div class="box" (clickedOutside)="showHidePopup()">
    <div class="header" [style.padding-top]="headerPadding">
      <i class="fas fa-chevron-left icon mob" (click)="showHidePopup()"></i>
      <i class="fas fa-times icon desk" (click)="showHidePopup()"></i>
      <h5>{{title}}</h5>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
