<button class="btn btn-outline-primary" placement="bottom" [ngbPopover]="accounts" popoverClass="nav-popover"
        [autoClose]="true">
  Switch Profile
</button>
<ng-template #accounts>
  <div class="box-heading">
    <span>Switch Profile</span>
  </div>
  <profile-list-selector [selectedProfile]="selectedProfile" [profiles]="profiles"></profile-list-selector>
  <button class="btn btn-outline-primary btn-block" type="button">
    Add New Account
  </button>
</ng-template>
