<div class="container">
    <h4 class="m-b-0">
        <span class="icon-text">Reset Password</span>
    </h4>
    <form (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm">
      <div class="form-group">
          <input formControlName="password" type="password" class="form-control" placeholder="Password">
          <div *ngIf="(controls.password.touched||submitted) && controls.password.invalid" class="text-danger text-left">
            <small *ngIf="controls.password.errors.required">Password is required.</small>
            <small *ngIf="controls.password.errors.passwordNotComplex">Password must contain atleast one alphabet and
              number.</small>
            <small *ngIf="controls.password.errors.minlength||controls.password.errors.passwordTooShort">
              Password must be at least 8 characters.
            </small>
          </div>
        </div>
        <div class="form-group">
          <input formControlName="confirmPassword" type="password" class="form-control" placeholder="Confirm Password">
          <div *ngIf="controls.confirmPassword.touched && controls.invalid">
            Password is required.
          </div>
          <div *ngIf="controls.confirmPassword.errors.confirmedValidator">
              Password and Confirm Password must match.
          </div>
          <button class="btn btn-azure form-control mt-2" type="submit" [disabled]="showWait">
              <i *ngIf='showWait' class='fa fa-paw fa-spin'></i>Submit
            </button>
        </div>
    </form>
</div>
