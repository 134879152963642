<div class="notification-popup">
  <div class="header">
    <h4 class="heading d-inline-block">Friend Requests</h4>
  </div>
  <div class="box">
    <div *ngIf="loading">
      <div *ngFor="let i of [0, 1]">
        <div class="item">
          <ngx-skeleton-loader
            appearance="circle"
            [theme]="{ width: '40px', height: '40px' }"
          >
          </ngx-skeleton-loader>
          <div class="content">
            <div class="d-flex justify-content-between">
              <ngx-skeleton-loader
                [theme]="{
                  width: '60px',
                  'border-radius': '5px',
                  height: '10px',
                  margin: 0
                }"
              ></ngx-skeleton-loader>
              <ngx-skeleton-loader
                [theme]="{
                  width: '80px',
                  'border-radius': '5px',
                  height: '10px',
                  margin: 0
                }"
              ></ngx-skeleton-loader>
            </div>
            <ngx-skeleton-loader
              [theme]="{
                width: '100px',
                'border-radius': '5px',
                height: '10px'
              }"
            ></ngx-skeleton-loader>
            <div class="d-flex">
              <ngx-skeleton-loader
                [theme]="{
                  width: '80px',
                  'border-radius': '10px',
                  height: '60px',
                  'margin-right': '10px'
                }"
              ></ngx-skeleton-loader>
              <ngx-skeleton-loader
                [theme]="{
                  width: '80px',
                  'border-radius': '10px',
                  height: '60px'
                }"
              ></ngx-skeleton-loader>
            </div>
          </div>
        </div>
        <hr class="my-2" *ngIf="i + 1 !== 2"/>
      </div>
    </div>


    <div *ngFor="let request of friendRequests; let i = index">
      <div class="item">
        <img class="rounded-circle mr-2" [src]="request.from.profilePicture?.location"
             [fallbackSrc]="DEFAULT_PROFILE_PICTURE"
             alt="profile" width="40"
             height="40"/>
        <div class="content">
          <div class="d-flex justify-content-between">
            <h6 class="m-0">{{ request.from.name }}</h6>
            <span>{{ request.from.breed.name }}</span>

            <p class="m-0">{{ request.receivedOn| retrospective }}</p>
          </div>
          <span>{{ request.from.caregiver.name}}</span>
          <div class="controls">
            <i class="fa fa-check control" (click)="accept(request)"></i>
            <i class="fa fa-times control" (click)="reject(request)"></i>
          </div>

        </div>
      </div>
      <hr class="my-2" *ngIf="i + 1 !== friendRequests.length"/>
    </div>
  </div>
</div>
