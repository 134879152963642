import {Component} from '@angular/core';

@Component({
  selector: 'app-single-post',
  templateUrl: './directLink.component.html',
  styleUrls: ['./directLink.component.scss']
})
export class DirectLinkComponent {
  constructor() {
  }
}
