import {BrowserModule} from "@angular/platform-browser";
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {JwtModule} from "@auth0/angular-jwt";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {PickerModule} from "@ctrl/ngx-emoji-mart";
import {AndroidNotch} from '@awesome-cordova-plugins/android-notch/ngx';

import {AppComponent} from "./app.component";
import {HomeComponent} from "@pettly/components/home/home.component";
import {ProfileComponent} from "@pettly/components/profile/profile.component";
import {HeaderComponent} from "@pettly/components/navbar/header.component";
import {TimelineComponent} from "@pettly/components/timeline/timeline.component";
import {CreatePostComponent} from "@pettly/components/timeline/createPost/createPost.component";
import {UserDetailComponent} from "@pettly/components/home/userDetail/userDetail.component";
import {PostComponent} from "@pettly/components/timeline/post/post.component";
import {AlertsComponent} from "@pettly/components/home/alerts/alerts.component";
import {AccountComponent} from "@pettly/components/account/account.component";
import {CreateProfileComponent} from "@pettly/components/createprofile/createprofile.component";
import {PetCatalogComponent} from "@pettly/components/catalog/petCatalog.component";
import {NotFoundComponent, ServerErrorComponent,} from "@pettly/components/errorPages/errorPages.component";
import {ProfileFilterPipe} from "@pettly/components/profile/profile-filter.pipe";
import {AuthService} from "@pettly/services/auth/auth.service";
import {AuthStorageService} from "@pettly/services/auth/auth-storage.service";
import {PostService} from "@pettly/services/post/post.service";
import {ProfileService} from "@pettly/services/profile/petprofile.service";
import {BreedService} from "@pettly/services/breed/breed.service";
import {LinkPreviewService} from "@pettly/services/ui/linkPreview.service";
import {OEmbedService} from "@pettly/services/ui/oEmbed.service";
import {Constants} from "@pettly/shared/constants";
import {ApiRequestInfrastructure} from "@pettly/services/apiRequestInfrastructure";
import {LoginComponent} from "@pettly/components/account/login/login.component";
import {RegistrationComponent} from "@pettly/components/account/registration/registration.component";
import {ProfileDetailComponent} from "@pettly/components/profile/profileDetail/profile-detail.component";
import {PetCatalogService} from "@pettly/services/catalog/petCatalog.service";
import {RetrospectiveDateFormatPipe} from "@pettly/libraries/date-pipe";
import {GenderPipe} from "@pettly/libraries/gender-pipe";
import {SafeHtmlPipe} from "@pettly/libraries/safeHtmlPipe";
import {Guid} from "./_models/Guid";
import {SessionRecoveryInterceptor} from "@pettly/services/interceptorV2";
import {FooterComponent} from "@pettly/components/footer/footer.component";
import {PrivacyPolicyComponent} from "@pettly/components/privacy-policy/privacy-policy.component";
import {ForgotPasswordComponent} from "@pettly/components/account/forgotpassword/forgotpassword.component";
import {
  ResetPasswordConfirmationComponent
} from "@pettly/components/account/resetpasswordconfirmation/resetpasswordconfirmation.component";
import {ResetPasswordComponent} from "@pettly/components/account/resetpassword/resetpassword.component";
import {NavbarSearchComponent} from "./components/navbar/search/search.component";
import {SharedUiService} from "@pettly/services/ui/sharedUi.service";
import {ServiceWorkerModule} from "@angular/service-worker";
import {environment} from "../environments/environment";
import {DirectLinkComponent} from "./components/singlePost/directLink.component";
import {ActivityFeedComponent} from "@pettly/components/profile/activity-feed/activityFeed.component";
import {ActivityComponent} from "@pettly/components/profile/activity-feed/activity/activity.component";
import {NotificationComponent} from "@pettly/components/navbar/notification/notification.component";
import {NotificationService} from "@pettly/services/notification/notification.service";
import {DialogComponent} from "./components/dialog/dialog.component";
import {ContainerBoxComponent} from "./components/container-box/container-box.component";
import {FriendListComponent} from "./components/profile/friend-list/friend-list.component";
import {ShortRetrospectiveDateFormatPipe} from "@pettly/libraries/date-short-pipe";
import {PostSkeletonComponent} from './components/timeline/post/postSkeleton/post-skeleton/post-skeleton.component';
import {ConfirmEmailComponent} from './components/account/confirm-email/confirm-email.component';
import {CountPipe} from '@pettly/libraries/count-pipe';
import {ImageFallbackDirective} from './_libraries/image-fallback.directive';
import {SwitchProfileComponent} from './components/profile/switch-profile/switch-profile.component';
import {ProfileListSelectorComponent} from './components/profile/profile-list-selector/profile-list-selector.component';
import {EditProfileComponent} from './components/profile/edit-profile/edit-profile.component';
import {NgxLinkifyjsModule} from '@code-name-jack/ngx-linkifyjs';
import {CommentComponent} from './components/timeline/post/comment/comment.component';
import {FriendRequestComponent} from './components/navbar/friend-request/friend-request.component';
import {PopupComponent} from './components/core/popup/popup.component';
import {PopupService} from "@pettly/components/core/popup/popup.service";
import {ClickedOutsideDirective} from './_libraries/clicked-outside.directive';
import {GlobalErrorHandler} from "@pettly/services/error/errorHandler.service";

export function jwtTokenGetter(): string {
  return localStorage.getItem(Constants.TOKEN_KEY);
}

export function initApp(sharedDataService: AuthStorageService,
                        profileService: ProfileService) {
  return async (): Promise<void> => {
    if (!sharedDataService.getClientId()) {
      sharedDataService.setClientId(Guid.create());
    }
    if (sharedDataService.getAuthToken() && !await sharedDataService.isTokenExpired()) {
      await profileService.init();
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AccountComponent,
    LoginComponent,
    RegistrationComponent,
    HomeComponent,
    ProfileComponent,
    HeaderComponent,
    TimelineComponent,
    CreatePostComponent,
    UserDetailComponent,
    ProfileDetailComponent,
    PostComponent,
    AlertsComponent,
    CreateProfileComponent,
    PetCatalogComponent,
    NotFoundComponent,
    ServerErrorComponent,
    ProfileFilterPipe,
    RetrospectiveDateFormatPipe,
    ShortRetrospectiveDateFormatPipe,
    GenderPipe,
    FooterComponent,
    PrivacyPolicyComponent,
    ForgotPasswordComponent,
    ResetPasswordConfirmationComponent,
    ResetPasswordComponent,
    NavbarSearchComponent,
    DirectLinkComponent,
    ActivityFeedComponent,
    ActivityComponent,
    NotificationComponent,
    SafeHtmlPipe,
    DialogComponent,
    ContainerBoxComponent,
    FriendListComponent,
    PostSkeletonComponent,
    ConfirmEmailComponent,
    CountPipe,
    ImageFallbackDirective,
    SwitchProfileComponent,
    ProfileListSelectorComponent,
    EditProfileComponent,
    CommentComponent,
    FriendRequestComponent,
    PopupComponent,
    ClickedOutsideDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    NgxSkeletonLoaderModule,
    PickerModule,
    NgxLinkifyjsModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: [
          "localhost:62541",
          "localhost:62540",
          "localhost:8080",
          "localhost:5000",
          "api.pettly.in",
          "pettlyapi.selwe.com",
        ],
      },
    }),
    NgbModule,
    RouterModule.forRoot(
      [
        {path: "home", redirectTo: "", pathMatch: "full"},
        {path: "", component: HomeComponent},
        {
          path: "",
          component: AccountComponent,
          data: {isHeaderVisible: false},
          children: [
            {path: "login", component: LoginComponent},
            {path: "register", component: RegistrationComponent},
            {path: "forgot-password", component: ForgotPasswordComponent},
            {path: "reset-password", component: ResetPasswordComponent},
          ],
        },
        {path: "profile/:id", component: ProfileComponent},
        {path: "profile", component: ProfileComponent},
        {path: "search", component: PetCatalogComponent},
        {path: "explore", component: PetCatalogComponent},
        {path: "createProfile", component: CreateProfileComponent, data: {isHeaderVisible: false}},
        {path: "notification", component: NotificationComponent},
        {path: "serverError", component: ServerErrorComponent, data: {isHeaderVisible: false}},
        {path: "privacy-policy", component: PrivacyPolicyComponent, data: {isHeaderVisible: false}},
        {
          path: "resetpasswordconfirmation",
          component: ResetPasswordConfirmationComponent,
          data: {isHeaderVisible: false}
        },
        {
          path: "confirm-email",
          component: ConfirmEmailComponent,
          data: {isHeaderVisible: false}
        },
        {path: "**", component: NotFoundComponent, data: {isHeaderVisible: false}},
      ],
      {scrollPositionRestoration: "enabled"}
    ),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    AuthService,
    PostService,
    ProfileService,
    BreedService,
    AuthStorageService,
    PetCatalogService,
    SharedUiService,
    LinkPreviewService,
    OEmbedService,
    NotificationService,
    ApiRequestInfrastructure,
    PopupService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionRecoveryInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [AuthStorageService, ProfileService],
    },
    {provide: Window, useValue: window},
    {provide: LOCALE_ID, useValue: 'en-US'},
    AndroidNotch,
    {provide: ErrorHandler, useClass: GlobalErrorHandler}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
