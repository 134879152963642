import {Injectable} from '@angular/core';
import {SocialAuthProvider, SocialLoginResult} from "@pettly/services/auth/sociaAuthProvider";
import {FacebookLogin} from "@capacitor-community/facebook-login";

@Injectable({
  providedIn: 'root'
})
export class FacebookService extends SocialAuthProvider {
  readonly providerName = "FACEBOOK";

  protected initializeImpl(): Promise<void> {
    return FacebookLogin.initialize(
      {
        appId: '267219010990207',
      });
  }

  async signIn(): Promise<SocialLoginResult> {
    const result = await FacebookLogin.login({permissions: ['email', 'public_profile']});
    return {token: result.accessToken.token};
  }

  async signOut(): Promise<void> {
    await FacebookLogin.logout();
  }
}
