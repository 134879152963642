<div>
  <h1 class="app-logo"><i class="fa fa-paw"></i> Pettly</h1>
  <p class="text-muted">Sign up to Pettly</p>
  <div class="social-btns">
    <button class="btn btn-social mt-4" (click)="googleLogin()">
      <div class="logo-box">
        <img src="assets/icons/google.png" alt="Google logo" width="20"/>
      </div>
      <span>Sign in with Google</span>
    </button>
    <button class="btn btn-social mt-4" (click)="fbLogin()">
      <div class="logo-box">
        <img src="assets/icons/facebook.png" alt="Facebook logo" width="20"/>
      </div>
      <span>Sign in with Facebook</span>
    </button>
  </div>
  <span class="divider text-muted mt-4 mb-3">Or</span>
</div>
<p class="text-muted">Enter caregiver details</p>
<form (ngSubmit)="register()" [formGroup]="registrationForm">
  <div *ngIf="(registrationForm.touched || submitted) && registrationForm.errors" class="text-danger text-left">
    <small *ngIf="registrationForm.errors.duplicateEmail">Email is already registered.</small>
  </div>
  <div class="form-group">
    <b>Name</b>
    <input formControlName="name" type="text" class="form-control"/>
    <div *ngIf="(controls.name.touched || submitted) && controls.name.errors" class="text-danger text-left">
      <small *ngIf="controls.name.errors.required">Name is required.</small>
    </div>
  </div>
  <div class="form-group">
    <b>Email</b>
    <input formControlName="email" type="email" class="form-control"/>
    <div *ngIf="(controls.email.touched || submitted) && controls.email.errors" class="text-danger text-left">
      <small *ngIf="controls.email.errors.required">Email is required</small>
      <small *ngIf="controls.email.errors.email">Enter a valid email.</small>
      <small *ngIf="controls.email.errors.duplicateEmail">Email is already registered.</small>
    </div>
  </div>
  <div class="form-group">
    <b>Password</b>
    <input formControlName="password" type="password" class="form-control"/>
    <div *ngIf="(controls.password.touched || submitted) && controls.password.invalid" class="text-danger text-left">
      <small *ngIf="controls.password.errors.required">Password is required.</small>
      <small *ngIf="controls.password.errors.passwordNotComplex">
        Password must contain atleast one alphabet and number.</small>
      <small *ngIf="controls.password.errors.minlength ||controls.password.errors.passwordTooShort">
        Password must be at least 8 characters.
      </small>
    </div>
  </div>
  <button type="submit" class="btn btn-login">
    <i *ngIf="showWait" class="fa fa-paw fa-spin"></i>Register
  </button>
</form>
<p>Already a member? <a routerLink="../login">Login</a></p>
