import {Injectable} from '@angular/core';
import {PetProfile} from '@pettly/models/petProfile';
import {API_URL} from '@pettly/shared/API_URL';
import {ApiRequestInfrastructure} from '../apiRequestInfrastructure';
import {PetProfileStorageService} from "@pettly/services/profile/pet-profile-storage.service";
import {AuthService} from "@pettly/services/auth/auth.service";

@Injectable({providedIn: 'root'})
export class ProfileService {
  constructor(
    private apiRequestInfrastructure: ApiRequestInfrastructure,
    private storage: PetProfileStorageService,
    private authService: AuthService
  ) {
    this.authService.userChanged.subscribe(userId => {
        if (!userId) {
          this.storage.clear();
        }
      }
    );
  }

  public async init(): Promise<void> {
    try {
      await this.getDefaultProfile();
    } catch (error) {
      console.log(error)
    }
  }

  async getPetProfiles(): Promise<PetProfile[]> {
    let profiles = this.storage.petProfiles();
    if (!profiles || profiles.length == 0) {
      const url = `${API_URL.profiles({caregiverId: this.authService.getCaregiverId()})}`;
      profiles = (await this.apiRequestInfrastructure.getAll<PetProfile>(url)) ?? [];

      this.storeProfiles(profiles);
    }
    return profiles;
  }

  public async switchDefaultProfile(profile: PetProfile): Promise<void> {
    this.storage.setActiveProfile(profile);
  }

  /*
    deletePetProfileById(id: any): Observable<Response> {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      const url = API_URL.PROFILES_GET + '/' + id;
      return this.http.delete(url, { headers }).pipe(
        tap(data => console.log('deletePetProfile' + JSON.stringify(data))),
        catchError(this.handleError));
    } */

  public savePetProfile(petProfile: PetProfile): Promise<PetProfile> {
    if (!petProfile.id || petProfile.id === '') {
      return this.createPetProfile(petProfile);
    }
    return this.updatePetProfile(petProfile);
  }

  public updateBreed(profileId: string, breedId: string): Promise<PetProfile> {
    const url = `${API_URL.profiles({caregiverId: this.authService.getCaregiverId()})}/${profileId}/breed`;
    const body = `"${breedId}"`;
    return this.apiRequestInfrastructure.put<PetProfile>(url, body);
  }

  private async getDefaultProfile(): Promise<PetProfile> {
    let defaultPetProfile = this.storage.activeProfile();
    if (!defaultPetProfile) {
      defaultPetProfile = this.storage.petProfiles()[0]
      await this.switchDefaultProfile(defaultPetProfile);
    }
    return defaultPetProfile;
  }

  private createPetProfile(petProfile: PetProfile): Promise<PetProfile> {
    const body = JSON.stringify(petProfile);
    const url = `${API_URL.profiles({caregiverId: this.authService.getCaregiverId()})}`;
    return this.apiRequestInfrastructure.post<PetProfile>(url, body);
  }

  private updatePetProfile(petProfile: PetProfile): Promise<PetProfile> {
    const url = `${API_URL.profiles({caregiverId: this.authService.getCaregiverId()})}/${petProfile.id}`;
    return this.apiRequestInfrastructure.put<PetProfile>(url, petProfile);
  }

  private storeProfiles(profiles: PetProfile[]) {
    this.storage.setAllProfiles(profiles);
  }
}
