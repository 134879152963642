import {Injectable, Signal, signal, WritableSignal} from '@angular/core';
import {PetProfile} from "@pettly/models/petProfile";
import {Constants} from "@pettly/shared/constants";

@Injectable({providedIn: 'root'})
export class PetProfileStorageService {
  private static readonly activeProfileKey = 'activeProfile';
  private static readonly profilesKey = 'profiles';

  private _activeProfile: WritableSignal<PetProfile> = signal(this.getActiveProfileFromLocalStorage());
  private _petProfiles: WritableSignal<PetProfile[]> = signal([]);

  public get activeProfile(): Signal<PetProfile> {
    return this._activeProfile;
  }

  public get petProfiles(): Signal<PetProfile[]> {
    return this._petProfiles;
  }

  private getActiveProfileFromLocalStorage(): PetProfile {
    return JSON.parse(localStorage.getItem(PetProfileStorageService.activeProfileKey));
  }

  public setActiveProfile(profile: PetProfile) {
    if (!profile.profilePictureLocation) {
      profile.profilePictureLocation = Constants.DEFAULT_PROFILE_PICTURE;
    }
    this._activeProfile.set(profile);
    localStorage.setItem(PetProfileStorageService.activeProfileKey, JSON.stringify(profile));
  }

  public setAllProfiles(profiles: PetProfile[]) {

    this._petProfiles.set(profiles);
    //localStorage.setItem()
    this.updateDefaultProfileIfOldOneIsDeleted(profiles);
  }

  private updateDefaultProfileIfOldOneIsDeleted(profiles: PetProfile[]) {
    if (!this.activeProfile()
      || (this.activeProfile() && !profiles.find(p => p.id === this.activeProfile().id))) {
      this.setActiveProfile(this.petProfiles()[0])
    }
  }

  public clear() {
    localStorage.removeItem(PetProfileStorageService.activeProfileKey);
    this._petProfiles.set(undefined);
    this._activeProfile.set(null);
  }
}
