<!--Settings Page-->
<div *ngIf="!showProfile">
  <div class="m-4 d-flex justify-content-between">
    <div>
      <img
        class="profile-pic"
        src="{{selectedProfile().profilePictureLocation}} "
        alt="Profile image example"
        width="80"
        height="80"
      />
      <h4 class="mb-1">{{ selectedProfile().name }}</h4>
      <p>{{ selectedProfile().breed.name }}</p>
    </div>
    <div>
      <button class="btn btn-outline-primary" (click)="popup.showHidePopup()">
        Switch Account
      </button>
    </div>
  </div>
  <div class="profile-options">
    <div class="item" (click)="showProfileDetails()">
      <button><img src="assets/icons/dog.svg" alt="view_profile"/></button>
      <h6 class="m-0">View Profile</h6>
    </div>
    <div class="item">
      <button><img src="assets/icons/add.svg" alt="add_pet"/></button>
      <h6 class="m-0">Add Pet</h6>
    </div>
    <div class="item">
      <button><img src="assets/icons/setting.svg" alt="setting"/></button>
      <h6 class="m-0">Settings</h6>
    </div>
    <div class="item" (click)="logoutClicked()">
      <button><img src="assets/icons/logout.svg" alt="logout"/></button>
      <h6 class="m-0">Logout</h6>
    </div>
  </div>
</div>
<!--End Settings Page-->

<div class="container-md page-content" *ngIf="showProfile">
  <div class="row profile">
    <div class="col-md-12">
      <div class="cover">
        <div class="cover-image">
          <img
            class="cover-pic"
            src="assets/images/Profile/profile-cover.jpg"
            alt="Profile image"
          />
          <img
            class="profile-pic"
            [src]="selectedProfile().profilePictureLocation"
            [fallbackSrc]="fallbackProfilePicture"
            alt="Profile image"
          />
        </div>
        <div class="cover-details">
          <div class="profile-details">
            <div>
              <h4>{{ selectedProfile().name }}</h4>
              <p>{{ selectedProfile().breed.name }}</p>
            </div>
            <div style="display: flex; align-items: center;">
              <div class="profile-box"><b>245</b><small>Friends</small></div>
              <div class="profile-box"><b>45</b><small>Posts</small></div>
            </div>
          </div>
          <div class="profile-btn">
            <switch-profile class="desk" [selectedProfile]="selectedProfile()" [profiles]="profiles()"></switch-profile>
            <button *ngIf="isOwnProfile" class="mob btn btn-outline-primary" (click)="popup.showHidePopup()">
              Switch Profile
            </button>
            <button *ngIf="isOwnProfile" class="btn btn-outline-primary" (click)="editProfile.showHidePopup()">
              Edit Profile
            </button>
            <button *ngIf="!isOwnProfile" class="btn btn-outline-primary">
              Follow
            </button>
          </div>
        </div>
      </div>
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
          <a ngbNavLink>About</a>
          <ng-template ngbNavContent>
            <app-profile-detail
              [activeProfile]="selectedProfile()"
              [isOwnProfile]="isOwnProfile"
            ></app-profile-detail>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink>Posts</a>
          <ng-template ngbNavContent>
            <app-activity-feed [profile]="selectedProfile()"></app-activity-feed>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink>Friends(254)</a>
          <ng-template ngbNavContent>
            <app-friend-list [activeProfile]="selectedProfile()"></app-friend-list>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink>Groups</a>
          <ng-template ngbNavContent>Groups</ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="tabs-content"></div>
    </div>
  </div>
  <!-- /container -->

  <div class="row mt-3 profile-container">
    <div class="col-md-4">
      <app-container-box heading="About me">
        <app-profile-detail
          [activeProfile]="selectedProfile()"
          [isOwnProfile]="isOwnProfile"
        ></app-profile-detail>
      </app-container-box>
      <app-container-box heading="Friends" [count]="254">
        <app-friend-list [activeProfile]="selectedProfile()"></app-friend-list>
      </app-container-box>
    </div>
    <!--============= timeline posts -->
    <div class="col-md-8">
      <app-activity-feed [profile]="selectedProfile()"></app-activity-feed>
    </div>
    <!-- end timeline posts-->
  </div>
</div>

<app-dialog class="profile-popup" title="Switch Profile" #popup>
  <profile-list-selector class="content" [selectedProfile]="selectedProfile()"
                         [profiles]="profiles()"></profile-list-selector>
  <button class="btn btn-primary profile-btn" type="button">Add New Account</button>
</app-dialog>

<app-dialog [title]="'Edit Profile'" #editProfile>
  <edit-profile></edit-profile>
</app-dialog>
