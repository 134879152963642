export class LinkPreview {
  url: string
  title: string;
  description: string;
  image: string;
  video: string;
  type: string;
  public constructor(url: string, title: string, description: string, image: string, video: string, type: string) {
    this.url = url;
    this.title = title;
    this.description = description;
    this.image = image;
    this.video = video;
    this.type = type;
  }
}
