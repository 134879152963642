import {Component, Input, OnInit} from '@angular/core';
import {PetProfile} from "@pettly/models/petProfile";
import {Notification, NotificationService, Pet} from "@pettly/services/notification/notification.service";
import {PetCatalogService} from "@pettly/services/catalog/petCatalog.service";
import {FriendRequest} from "@pettly/services/catalog/friend-request";
import {Constants} from "@pettly/shared/constants";

@Component({
  selector: 'navbar-friend-request',
  templateUrl: './friend-request.component.html',
  styleUrls: ['./friend-request.component.scss']
})
export class FriendRequestComponent implements OnInit {
  public readonly DEFAULT_PROFILE_PICTURE = Constants.DEFAULT_PROFILE_PICTURE;
  @Input() activeProfile: PetProfile;
  public loading = true;
  public readNotifications: Notification[] = [];
  public friendRequests: FriendRequest[] = [];

  constructor(private notificationService: NotificationService, private petCatalogService: PetCatalogService) {
  }

  async ngOnInit(): Promise<void> {
    this.friendRequests = await this.petCatalogService.getFriendRequests(this.activeProfile.id);
    this.loading = false;
  }

  async accept(request: FriendRequest): Promise<void> {
    await this.petCatalogService.acceptFriendRequests(this.activeProfile.id, request);
    this.friendRequests = await this.petCatalogService.getFriendRequests(this.activeProfile.id);
  }

  async reject(request: FriendRequest): Promise<void> {
    await this.petCatalogService.rejectFriendRequests(this.activeProfile.id, request);
    this.friendRequests = await this.petCatalogService.getFriendRequests(this.activeProfile.id);
  }
}
