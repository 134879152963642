import {Injectable} from '@angular/core';
import {PetProfile, PetSearchProfile} from '@pettly/models/petProfile';
import {API_URL} from '@pettly/shared/API_URL';
import {ApiRequestInfrastructure} from '@pettly/services/apiRequestInfrastructure';
import {environment} from 'environments/environment';
import {FriendRequest, Friendship} from "@pettly/services/catalog/friend-request";

@Injectable()
export class PetCatalogService {
  constructor(
    private apiRequestInfrastructure: ApiRequestInfrastructure,
  ) {
  }

  public getAllPetProfiles(): Promise<PetProfile[]> {
    return this.apiRequestInfrastructure.getAll<PetProfile>(API_URL.PET_CATALOG_GETALLPETS);
  }

  public searchPetProfilesFor(keyword: string /*,requestingPet: PetProfile*/): Promise<PetSearchProfile[]> {
    const uri = `${API_URL.PET_CATALOG_GETALLPETS}/search?query=${keyword}`
    return this.apiRequestInfrastructure.getAll<PetSearchProfile>(uri);
  }

  public getPetProfileById(id: any): Promise<PetProfile> {
    const url = environment.API_URL + `api/PetCatalog/${id}`;
    return this.apiRequestInfrastructure.get<PetProfile>(url);
  }

  public subscribe(fromId: string, petId: string): Promise<PetProfile> {
    const url = `${API_URL.petFeedSubscriptions({petId})}/requests/${fromId}`;
    return this.apiRequestInfrastructure.put<any>(url, null);
  }

  public getFriends(petId: string): Promise<Friendship[]> {
    const url = `${API_URL.petFeedSubscriptions({petId})}`;
    return this.apiRequestInfrastructure.get<Friendship[]>(url);
  }

  public getFriendRequests(petId: string): Promise<FriendRequest[]> {
    const url = `${API_URL.petFeedSubscriptions({petId})}/requests`;
    return this.apiRequestInfrastructure.get<FriendRequest[]>(url);
  }

  public acceptFriendRequests(petId: string, request: FriendRequest): Promise<void> {
    const url = `${API_URL.petFeedSubscriptions({petId})}/requests/${request.from.id}/confirm`;
    return this.apiRequestInfrastructure.post<any>(url, null);
  }

  public rejectFriendRequests(petId: string, request: FriendRequest): Promise<void> {
    const url = `${API_URL.petFeedSubscriptions({petId})}/requests/${request.from.id}`;
    return this.apiRequestInfrastructure.delete(url);
  }

  public getRecommendedProfilesFor(activeProfileId: string): Promise<PetSearchProfile[]> {
    const uri = `${API_URL.PET_CATALOG_GETALLPETS}/${activeProfileId}/recommendations`;
    return this.apiRequestInfrastructure.getAll<PetSearchProfile>(uri);
  }
}
