export class User {
  name: string;
  email: string;
  password: string;
  requestUri: string;

  constructor() {
  }
}

export class Address {
  country: string;
  province: string;
  city: string;
}

