<div class="profile-btn"
     [ngClass]="{active: selectedProfile  && selectedProfile.id === profile.id}" *ngFor="let profile of profiles">
  <div class="d-flex align-items-center" (click)="switchProfile(profile)">
    <img class="rounded-circle mr-3" [src]="profile.profilePictureLocation" [fallbackSrc]="fallBackProfilePicture"/>
    <div>
      <h6 class="m-0">{{ profile.name }}</h6>
      <small>{{ profile.breed.name }}</small>
    </div>
  </div>
</div>
