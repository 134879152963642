export class ForgotPasswordModel{
    email: string;
    requestUri: string;
}

export class ResetPasswordModel{
    email: string;
    password: string;
    code: string;
}
