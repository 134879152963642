import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {PetProfile} from "@pettly/models/petProfile";
@Component({
  selector: 'switch-profile',
  templateUrl: './switch-profile.component.html',
  styleUrls: ['./switch-profile.component.scss']
})
export class SwitchProfileComponent {
  @Input() public selectedProfile: PetProfile;
  @Input() public profiles: PetProfile[];
  constructor() {}
}
