<div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()"
     class="timeline">
  <createPost-element class="create-post" [profileId]="activeProfile.id"
                      (postCreated)="postCreatedHandler($event)">
  </createPost-element>
  <post-element *ngFor="let postUnit of posts" [post]="postUnit" (menuClicked)="menuClickedHandler($event)"
                [activeProfile]="activeProfile">
  </post-element>
  <ng-container *ngIf="loading">
    <app-post-skeleton> *ngFor="let i of [].constructor(3)"</app-post-skeleton>
  </ng-container>
</div>

<ng-template let-post='post' #menu>
  <div class="menu">
    <div class="menu-item" ngbDropdownItem><i class="fa fa-user-minus"></i> Unfollow</div>
    <div class="menu-item" ngbDropdownItem><i class="fa fa-share-alt"></i> Share</div>
    <div (click)="removePost(post)" class="menu-item" ngbDropdownItem><i class="fa fa-eraser"></i> Delete
    </div>
    <div class="menu-item" ngbDropdownItem><i class="far fa-flag" style="color:orangered;"></i> Report
    </div>
  </div>
</ng-template>
