<div class="box">
  <div class="profile-section">
    <a [routerLink]="['profile']">
      <img
        class="rounded-circle"
        [src]="activeProfile.profilePictureLocation"
        width="80"
        height="80"
        alt="profile"
      />
    </a>
    <h4>{{ activeProfile.name }}</h4>
    <p>{{ activeProfile.breed.name }}</p>
    <switch-profile [profiles]="profiles" [selectedProfile]="activeProfile"></switch-profile>
  </div>
  <div class="profile-category">
    <div class="item">
      <button><img src="assets/icons/cow.svg" alt="cow"/></button>
      <span>Cow</span>
    </div>
    <div class="item">
      <button><img src="assets/icons/cat.svg" alt="cat"/></button>
      <span>Cat</span>
    </div>
    <div class="item">
      <button><img src="assets/icons/dog.svg" alt="dog"/></button>
      <span>Dog</span>
    </div>
    <div class="item">
      <button><img src="assets/icons/parrot.svg" alt="parrot"/></button>
      <span>Parrot</span>
    </div>
  </div>
  <div class="profile-category">
    <div class="item">
      <button><img src="assets/icons/message.svg" alt="message"/></button>
      <span>Message</span>
    </div>
    <div class="item">
      <button><img src="assets/icons/events.svg" alt="events"/></button>
      <span>Events</span>
    </div>
    <div class="item">
      <button><img src="assets/icons/alerts.svg" alt="alerts"/></button>
      <span>Alerts</span>
    </div>
    <div class="item">
      <button><img src="assets/icons/requests.svg" alt="requests"/></button>
      <span>Requests</span>
    </div>
  </div>
</div>
<ng-template #accounts>
  <div class="box-heading">
    <span>Switch Account</span>
  </div>
  <div
    class="profile-btn"
    [ngClass]="{
      active:  profile.id === activeProfile?.id
    }"
    *ngFor="let profile of profiles;"
  >
    <div class="d-flex align-items-center clickable">
      <img
        class="rounded-circle mr-3"
        src="assets/images/Profile/profile1.jpg"
        width="40"
        height="40"
      />
      <div>
        <h6 class="m-0">{{ profile.name }}</h6>
        <small>{{ profile.breed.name }}</small>
      </div>
    </div>
  </div>
  <button class="btn btn-outline-primary btn-block" type="button">
    Add New Account
  </button>
</ng-template>
