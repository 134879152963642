import {Component} from '@angular/core';

@Component({
  selector: 'app-post-skeleton',
  templateUrl: './post-skeleton.component.html',
  styleUrls: ['../../post.component.scss']
})
export class PostSkeletonComponent {
  constructor() {
  }
}
