import {Injectable} from '@angular/core';
import {AndroidNotch} from "@awesome-cordova-plugins/android-notch/ngx";
import {Capacitor} from "@capacitor/core";

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {
  constructor(private androidNotch: AndroidNotch) {
  }

  public async hasNotch(): Promise<boolean> {
    return Capacitor.isNativePlatform() && this.androidNotch.hasCutout();
  }

  public async getInsetTop(): Promise<number> {
    return this.androidNotch.hasCutout().then(
      value => {
        if (value) return this.androidNotch.getInsetTop();
        else return 0;
      }
    )
  }
}
