import { PreviewFrom } from '@pettly/components/timeline/post/PreviewFrom';
import { DraftContent } from '@pettly/models/draftContent'
import { LinkPreviewService } from '@pettly/services/ui/linkPreview.service';
import { OEmbedService } from '@pettly/services/ui/oEmbed.service';
import { LinkPreview } from './linkPreview';

export class DraftPost {
  private _content: DraftContent;

  private _linkPreview: LinkPreview;
  public get linkPreview(): LinkPreview {
    return this._linkPreview;
  }

  public set content(value: string) {
    this._content = new DraftContent(value);
    this.postContentChanged();
  }

  public get content(): string {
    return this._content.toString();
  }

  private _postMedia: string;
  public get postMedia(): string {
    return this._postMedia;
  }

  private _oEmbedPreview: string;
  private _mediaPreview: string;

  public get mediaPreview(): string {
    return this._mediaPreview ?? this._oEmbedPreview
  }

  private _previewFrom: PreviewFrom;
  public get previewFrom(): PreviewFrom {
    return this._previewFrom
  }

  async setContentPreview(): Promise<void> {
    if (this._mediaPreview) {
      this._previewFrom = PreviewFrom.Image;
    }
    // else if (this.post.linkPreview.video) {
    //   this._previewFrom = PreviewFrom.Video;
    ////when video upload is allowed
    // }
    else if (this._oEmbedPreview) {
      this._previewFrom = PreviewFrom.Link;
    } else {
      this._previewFrom = PreviewFrom.None;
    }
  }


  constructor(private linkPreviewService: LinkPreviewService, private oEmbedService: OEmbedService) {
    this._content = new DraftContent('');
  }

  public clear(): void {
    this._content = new DraftContent('');
    this.removeMedia();
  }

  async updateMedia(result: string | ArrayBuffer): Promise<void> {
    this._mediaPreview = result as string;
    this._postMedia = this._mediaPreview.split(',')[1];
    await this.setContentPreview();
  }

  private async postContentChanged(): Promise<void> {
    await this.updateMediaPreviewIfEmpty();
  }

  private async updateMediaPreviewIfEmpty(): Promise<void> {
    if (this._postMedia) return;
    if (this._content?.containsHyperlinks()) {
      const preview = await this.linkPreviewService.linkPreviewFor(this._content.hyperlinks[0]);
      this._linkPreview = new LinkPreview(preview.url, preview.title, preview.description, preview.image, preview.video, preview.type);
      const oEmbed = (await this.oEmbedService.htmlFor(this._content.hyperlinks[0]))
      this._oEmbedPreview = oEmbed.html;
      if ((!this._linkPreview) || (!this._linkPreview.url)) this._linkPreview.url = oEmbed.url;
      await this.setContentPreview();
    }
  }

  public removeMedia(): void {
    this._postMedia = null;
    this._mediaPreview = null;
    this._linkPreview = undefined;
    this._oEmbedPreview = undefined;
    this._previewFrom = PreviewFrom.None;
  }

  public isPostable(): boolean {
    if (this._content && !this._content.isEmpty()) return true;
    if (this._mediaPreview) return true;
    return false;
  }
}

