import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-activity-element',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent {
  @Input() activity: string;


}
