import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[clickedOutside]'
})
export class ClickedOutsideDirective {
  constructor(private _elementRef: ElementRef) {
  }

  @Output()
  public clickedOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:mousedown', ['$event'])
  public onClick(event: MouseEvent): void {
    if (!this._elementRef.nativeElement.contains(event.target)) {
      this.clickedOutside.emit(event);
    }
  }
}
