import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Constants} from '@pettly/shared/constants';

@Pipe({
  name: 'retrospectiveShort'
})
export class ShortRetrospectiveDateFormatPipe extends DatePipe implements PipeTransform {
  override transform(value: Date, args?: any): any {
    return this.format(value);
  }

  private format(value: Date): string {
    let format: string;
    const aDay = 1000 * 3600 * 24;
    const diff = Math.abs(Date.now().valueOf() - (new Date(value)).valueOf());
    const diffDays = Math.ceil(diff / aDay);
    if (diffDays > 365) {
      format = Math.floor(diffDays / 365) + ' y';//Constants.DateFormat;
    } else if (diffDays > 7) {
      format = super.transform(value, 'MMM d');// Constants.DateFormatWithinSameYear;
    } else if (diffDays > 1) {
      format = super.transform(value, Constants.DateFormatWithinSameWeek);
    } else {
      format = super.transform(value, Constants.DateFormatWithin24Hrs) + ' Today';
    }
    return format;
  }
}
