import {Injectable, signal, WritableSignal} from '@angular/core';
import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import {API_URL} from "@pettly/shared/API_URL";
import {AuthStorageService} from "@pettly/services/auth/auth-storage.service";

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private hubConnection: any;
  public connected: WritableSignal<boolean> = signal(false);

  public constructor(private authStore: AuthStorageService) {
  }

  public startConnection() {
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(API_URL.notificationHub, {accessTokenFactory: () => this.authStore.getAuthToken()}).build();

      this.hubConnection.start()
        .then(() => {
          console.log("connection established");
          this.connected.set(true);
          return resolve(true);
        })
        .catch((err: any) => {
          console.log("error occured" + err);
          reject(err);
        });
    });
  }

  public listenToFeed(feedName: string, callback: (...args: any[]) => any) {
    (<HubConnection>this.hubConnection).on(feedName, callback);
  }
}
