import {Injectable} from '@angular/core';
import {ApiRequestInfrastructure} from '../apiRequestInfrastructure';
import {API_URL} from '@pettly/shared/API_URL';
import {PetBreed, PetCategory} from "@pettly/models/petProfile";

@Injectable()
export class BreedService {
  constructor(private apiRequest: ApiRequestInfrastructure) {
  }

  public getAllBreeds(groupId) {
    const url = `${API_URL.breeds({categoryId: groupId})}`;
    return this.apiRequest.getAll<PetBreed>(url);
  }

  async getAllGroups(): Promise<PetCategory[]> {
    return await this.apiRequest.getAll<PetCategory>(API_URL.petCategories());
  }
}
