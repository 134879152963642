<div class="notification-popup">
  <div class="header">
    <h4 class="heading d-inline-block">Notifications</h4>
  </div>
  <div class="box">
    <!-- <p *ngIf="newNotifications.length == 0 && !loading">No notifications</p> -->
    <div *ngIf="loading">
      <div *ngFor="let i of [0, 1]">
        <div class="item">
          <ngx-skeleton-loader
            appearance="circle"
            [theme]="{ width: '40px', height: '40px' }"
          >
          </ngx-skeleton-loader>
          <div class="content">
            <div class="d-flex justify-content-between">
              <ngx-skeleton-loader
                [theme]="{
                  width: '60px',
                  'border-radius': '5px',
                  height: '10px',
                  margin: 0
                }"
              ></ngx-skeleton-loader>
              <ngx-skeleton-loader
                [theme]="{
                  width: '80px',
                  'border-radius': '5px',
                  height: '10px',
                  margin: 0
                }"
              ></ngx-skeleton-loader>
            </div>
            <ngx-skeleton-loader
              [theme]="{
                width: '100px',
                'border-radius': '5px',
                height: '10px'
              }"
            ></ngx-skeleton-loader>
            <div class="d-flex">
              <ngx-skeleton-loader
                [theme]="{
                  width: '80px',
                  'border-radius': '10px',
                  height: '60px',
                  'margin-right': '10px'
                }"
              ></ngx-skeleton-loader>
              <ngx-skeleton-loader
                [theme]="{
                  width: '80px',
                  'border-radius': '10px',
                  height: '60px'
                }"
              ></ngx-skeleton-loader>
            </div>
          </div>
        </div>
        <hr class="my-2" *ngIf="i + 1 !== 2"/>
      </div>
    </div>

    <!-- <div class="notification" *ngFor="let notification of newNotifications">
      <div class="notification-image">
        <img class="rounded-circle author-dp" src="{{notification.actors[0].profilePictureUri}}">
      </div>
      <div class="notification-text">
        <span class="highlight"><b>{{commaSeparetedActorNames(notification.actors)}}</b></span> {{notification.action}}
        {{ notification.target.id==activeProfile.id?"you": notification.target.name}}
      </div>
    </div> -->
    <div *ngFor="let notification of notifications; let i = index">
      <div class="item">
        <img
          class="rounded-circle mr-2"
          [src]="notification.actors[0].profilePictureUri" [fallbackSrc]=""
          alt="profile"
          width="40"
          height="40"
        />
        <div class="content">
          <div class="d-flex justify-content-between">
            <h6 class="m-0"><b> {{commaSeparatedActorNames(notification.actors)}} </b> {{ notification.action }} you
            </h6>
          </div>

          <div class="d-flex">
            <!--            <img-->
            <!--              [src]="image.url"-->
            <!--              alt="'Image_'+image.id"-->
            <!--              *ngFor="let image of notification.targetActivity | slice: 0:2"-->
            <!--            />-->
          </div>
          <p class="m-0">{{ notification.publishedAt |retrospective }}</p>

        </div>
      </div>
      <hr class="my-2" *ngIf="i + 1 !== notifications.length"/>
    </div>
  </div>
</div>
