import { Component } from '@angular/core';

@Component({
    templateUrl: './404.errorPages.component.html',
    styleUrls: ['./errorPages.component.css']
})

export class NotFoundComponent { }

@Component({
    templateUrl: './500.errorPages.component.html',
    styleUrls: ['./errorPages.component.css']
})

export class ServerErrorComponent { }
