import {Component} from "@angular/core";

@Component({
  selector: "alerts-element",
  templateUrl: "./alerts.component.html",
  styleUrls: ["./alerts.component.scss"],
})
export class AlertsComponent {
  name: string;
  public trendings: {
    id: number;
    name: string;
    date: string;
    profile: string;
    images: {
      id: number;
      type: string;
      url: string;
    }[];
  }[] = [
    {
      id: 0,
      name: "Caty",
      date: "10:30 AM Today",
      profile: "assets/images/Profile/profile1.jpg",
      images: [
        {
          id: 0,
          type: "image",
          url: "assets/images/Post/image1.jpg",
        },
        {
          id: 1,
          type: "image",
          url: "assets/images/Post/image3.jpg",
        },
      ],
    },
    {
      id: 1,
      name: "Pluto the Puppy",
      date: "10:30 AM Today",
      profile: "assets/images/Profile/profile3.jpg",
      images: [
        {
          id: 0,
          type: "image",
          url: "assets/images/Post/image2.jpg",
        },
        {
          id: 1,
          type: "image",
          url: "assets/images/Post/image4.jpg",
        },
      ],
    },
  ];
  public profiles: {
    id: number;
    name: string;
    img: string;
    breedName: string;
  }[] = [
    {
      id: 0,
      name: "John Doe",
      img: "assets/images/Profile/profile4.jpg",
      breedName: "Austrailian Shephards",
    },
    {
      id: 1,
      name: "John Doe",
      img: "assets/images/Profile/profile4.jpg",
      breedName: "Austrailian Shephards",
    },
  ];

  constructor() {
    this.name = "same";
  }
}
