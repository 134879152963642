<div id="root">
    <div class="error-header"> </div>
    <div class="container ">
        <section class="error-container text-center">
            <h1 class="animated fadeInDown">500</h1>
            <div class="error-divider animated fadeInUp">
                <h2>ooops!!</h2>
                <p class="description">SOMETHING WENT WRONG.</p>
            </div>
            <a href="home" class="return-btn"><i class="fa fa-home"></i>Home</a>
        </section>
    </div>
</div>