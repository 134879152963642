<div class="container">
  <h4 class="m-b-0">
    <span class="icon-text">Forgot Password</span>
  </h4>

  <form (ngSubmit)="forgotPassword()" [formGroup]="form">
    <div class="form-group">
      <p class="text-muted" [hidden]="showWait||sendSuccessful">Enter your username or email.</p>
      <input formControlName="email" type="email" class="form-control" placeholder="Email" [hidden]="showWait||sendSuccessful"/>
      <div *ngIf="(controls.email.touched||submitted) && controls.email.errors" class="text-danger text-left">
        <small *ngIf="controls.email.errors.required">Email is required</small>
        <small *ngIf="controls.email.errors.email">Enter a valid email.</small>
      </div>
    </div>
    <button class="btn btn-azure form-control mt-2" type="submit" [hidden]="showWait||sendSuccessful">
      <i *ngIf='showWait' class='fa fa-paw fa-spin'></i>Submit
    </button>
    <div *ngIf='sendSuccessful' class="alert alert-success mt-2" role="alert">
      Email has been sent successfully. Please check your mailbox and click on confirmation link.
    </div>
  </form>
</div>
<a routerLink="/login"><small>Return to login page.</small></a>
