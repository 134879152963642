import {Component, Input} from '@angular/core';
import {PetProfile} from "@pettly/models/petProfile";
import {ProfileService} from "@pettly/services/profile/petprofile.service";
import {Constants} from "@pettly/shared/constants";

@Component({
  selector: 'profile-list-selector',
  templateUrl: './profile-list-selector.component.html',
  styleUrls: ['./profile-list-selector.component.scss']
})
export class ProfileListSelectorComponent {
  @Input() public selectedProfile: PetProfile;
  @Input() public profiles: PetProfile[];
  public fallBackProfilePicture = Constants.DEFAULT_PROFILE_PICTURE;

  constructor(private profileService: ProfileService) {
  }

  async switchProfile(profile: PetProfile): Promise<void> {
    await this.profileService.switchDefaultProfile(profile);
    this.selectedProfile = profile;
  }
}
