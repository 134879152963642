export class PetCategory {
  public id: string;
  public name: string;
}

export class PetBreed {
  public id: string;
  public name: string;
  public group = new PetCategory();
}


export class PetMedia {
  public location: string;
  public contentType: string;
  public ownerAccountId: number;
}

export class Gender {
  constructor(
    private name: string,
    public value: number
  ) {
  }

  public toString = (): string => this.name;
}

export class Genders {
  private static values = {
    'male': new Gender('Male', 1),
    'female': new Gender('Female', 2),
    'notKnown': new Gender('Not Known', 0),
    'notApplicable': new Gender('Not Applicable', 9),
  };

  public static get all() {
    return Object.values(this.values)
  };

  public static Male = this.values.male;
  public static FeMale = this.values.female;
  public static NotKnown = this.values.notKnown;
  public static NotApplicable = this.values.notApplicable;

  public static getGenderFromValue(value: number): Gender {
    switch (value) {
      case 1:
        return this.Male;
      case 2:
        return this.FeMale;
      case 0:
        return this.NotKnown;
      case 9:
      default:
        return this.NotApplicable;
    }
  }
}

export class PetProfile {
  public id: string;
  public name: string;
  public breed = new PetBreed();
  public breedId: string;
  public nickName: string;
  public description: string;
  public dateOfBirth: Date;
  public gender: number;
  public profilePictureLocation: string;
  public livesIn: string;
  public isActive: boolean;
  public isDeleted: boolean;

  public static formattedGender(value: number): Gender {
    return Genders.getGenderFromValue(value);
  }
}

export interface PetSearchProfile {
  id: string;
  name: string;
  ownerName: string;
  breed: string;
  category: string;
  gender: number;
  profilePictureUri: string;
}
