import {Component, EventEmitter, HostBinding, HostListener, Input, Output, ViewEncapsulation} from '@angular/core';
import {NgElement} from "@angular/elements";

declare global {
  interface HTMLElementTagNameMap {
    'pui-popup': NgElement;
  }
}

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class PopupComponent {
  @Output()
  closed = new EventEmitter<void>();

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.close();
    }
  }

  public close() {
    this.closed.next();
  }
}
