import {environment} from 'environments/environment';

export class API_URL {
  public static readonly ACCOUNT_REGISTER: string = environment.API_URL + 'api/identity/accounts';
  public static readonly ACCOUNT_TOKEN: string = environment.API_URL + 'api/identity/accounts/token';
  public static readonly ACCOUNT_TOKEN_REFRESH: string = environment.API_URL + 'api/identity/accounts/token/refresh';
  public static readonly ACCOUNT_EMAIL_CONFIRM: string = environment.API_URL + 'api/identity/accounts/email/confirm';

  public static readonly ACCOUNT_FORGOT_PASSWORD: string = environment.API_URL + 'api/identity/accounts/password/reset-token';
  public static readonly ACCOUNT_RESET_PASSWORD: string = environment.API_URL + 'api/identity/accounts/password/reset';

  public static readonly petCategories = () => `${environment.API_URL}api/configurations/pet/categories`;
  public static readonly breeds = ($: { categoryId: string }) => `${API_URL.petCategories()}/${$.categoryId}/breeds`;


  public static readonly PET_CATALOG_GETPET: string = environment.API_URL + 'api/PetCatalog/{id}';
  public static readonly PET_CATALOG_GETALLPETS: string = environment.API_URL + 'api/PetCatalog';

  public static readonly profiles = ($: {
    caregiverId: string
  }) => `${environment.API_URL}api/caregiver/${$.caregiverId}/pets`;

  public static readonly feeds = ($: { petId: string }) => `${environment.API_URL}api/pets/${$.petId}/feeds`;
  public static readonly posts = ($: { petId: string }) => `${environment.API_URL}api/pets/${$.petId}/feeds/posts`;
  public static readonly postComments = ($: { petId: string, postId: string }) =>
    `${environment.API_URL}api/pets/${$.petId}/feeds/posts/${$.postId}/comments`;
  public static readonly postReactionOnComments = ($: { petId: string, postId: string, commentId: string }) =>
    `${environment.API_URL}api/pets/${$.petId}/feeds/posts/${$.postId}/comments/${$.commentId}/reactions`;
  public static readonly postReactions = ($: { petId: string, postId: string }) =>
    `${environment.API_URL}api/pets/${$.petId}/feeds/posts/${$.postId}/reactions`;

  public static readonly petFeedSubscriptions = ($: { petId: string }) =>
    `${environment.API_URL}api/pets/${$.petId}/friends`;

  public static readonly notifications = ($: { petId: string }) =>
    `${environment.API_URL}api/pets/${$.petId}/notifications`;
  public static readonly notificationHub = `${environment.API_URL}hubs/notification`;

  public static readonly LINK_PREVIEW: string = environment.API_URL + 'api/external-content/url-preview?url=';
}
