import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder} from "@angular/forms";
import {AuthService} from "@pettly/services/auth/auth.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
})
export class ConfirmEmailComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    const param = await this.route.snapshot.queryParamMap;
    await this.authService.confirmEmail(param.get('email'), param.get('code'));
  }

}
