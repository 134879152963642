import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from '@pettly/services/auth/auth.service';
import {ApiError, ApiErrorResponse} from '@pettly/services/error/apiError';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SocialAuthProvider} from "@pettly/services/auth/sociaAuthProvider";

@Component({
  selector: 'app-login-element',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']

})

export class LoginComponent implements OnInit {
  showWait = false;
  loginForm: UntypedFormGroup;
  submitted = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  public get loginFormControls() {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(4)]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  async fbLogin(): Promise<void> {
    await this.socialLogin(this.authService.socialAuthProviders.facebook);
  }

  async googleLogin(): Promise<void> {
    await this.socialLogin(this.authService.socialAuthProviders.google);
  }

  private async socialLogin(provider: SocialAuthProvider) {
    try {
      const result = await provider.signIn();
      await this.onSocialLoginCompleted(result.token, provider.providerName);
    } catch (error) {
      alert(JSON.stringify(error))
    }
  }

  async onSocialLoginCompleted(token: string, provider: string): Promise<void> {
    try {
      if (!token) return;
      this.submitted = false;
      this.showWait = true;
      await this.authService.onSocialLogin(token, provider);
      await this.router.navigate(['/home']);
    } catch (err) {
      if (err instanceof ApiError) {
        err.errors.forEach(error => this.mapErrorCodeWithControlText(error));
      } else {
        alert('Login failed' + JSON.stringify(err));
      }
    } finally {
      this.showWait = false;
    }
  }

  public async login(): Promise<void> {
    try {
      this.submitted = true;
      this.showWait = true;
      await this.authService.login(this.loginFormControls.username.value, this.loginForm.controls.password.value);
      await this.router.navigate(['/home']);
    } catch (err) {
      if (err instanceof ApiError) {
        err.errors.forEach(error => this.mapErrorCodeWithControlText(error));
      } else {
        console.log('Login failed' + JSON.stringify(err));
      }
    } finally {
      this.showWait = false;
    }
  }

  private mapErrorCodeWithControlText(error: ApiErrorResponse): void {
    switch (error.code) {
      case 'UserDoesnotExist':
        this.loginFormControls["username"].setErrors({userDoesnotExist: true});
        break;
      case 'UserNamePasswordDoesntMatch':
        this.loginFormControls.password.setErrors({passwordDoesntMatch: true});
        break;
      case 'UserNotAllowed':
        this.loginFormControls["username"].setErrors({userNotAllowed: true});
        break;
      case 'EmailNotConfirmed':
        this.loginFormControls["username"].setErrors({emailNotConfirmed: true});
        break;
      default:
        console.log(JSON.stringify(error));
    }
  }
}

