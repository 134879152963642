<div id="root">
    <div class="error-header"> </div>
    <div class="container">
        <section class="error-container text-center">
            <h1 class="animated fadeInDown">404</h1>
            <div class="error-divider animated fadeInUp">
                <h2>PAGE NOT FOUND.</h2>
                <p class="description">We Couldn't Find This Page</p>
            </div>
            <a href="home" class="return-btn"><i class="fa fa-home"></i>Home</a>
        </section>
    </div>
</div>