import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Constants} from '@pettly/shared/constants';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {EMPTY, lastValueFrom, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ApiError} from '@pettly/services/error/apiError';

@Injectable()
export class ApiRequestInfrastructure {
  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  public get<T>(uri: string, anonymous?: boolean): Promise<T> {
    const options = this.getHeaders(anonymous);
    const response = this.http
      .get<T>(uri, options)
      .pipe(map(response => {
        return response;
      }), catchError(error => this.handleError(error, 'error in get')));
    return lastValueFrom(response);
  }

  public getAll<T>(uri: string, anonymous?: boolean): Promise<T[]> {
    const options = this.getHeaders(anonymous);
    const response = this.http
      .get<T[]>(uri, options)
      .pipe(map(response => {
        return response;
      }), catchError(error => this.handleError(error, 'error in getAll')));
    return lastValueFrom(response);
  }

  public post<T>(uri: string, body, anonymous?: boolean, customHeaders?: HttpHeaders): Promise<T> {
    const options = this.getHeaders(anonymous, customHeaders);
    const response = this.http
      .post<T>(uri, body, options)
      .pipe(map(response => {
        return response;
      }), catchError(error => this.handleError(error, 'error in post')));
    return lastValueFrom(response);
  }

  public put<T>(uri: string, body, anonymous?: boolean): Promise<T> {
    const options = this.getHeaders(anonymous);
    const response = this.http
      .put<T>(uri, body, options)
      .pipe(map(response => {
        return response;
      }), catchError(error => this.handleError(error, 'error in put')));
    return lastValueFrom(response);
  }

  public delete(uri: string): Promise<void> {
    const options = this.getHeaders();
    const response = this.http
      .delete(uri, options)
      .pipe(map(() => {
        return;
      }), catchError(error => this.handleError(error, 'error in delete')));
    return lastValueFrom(response);
  }

  public getHeaders(anonymous?: boolean, customHeaders?: HttpHeaders) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken()
    });
    if (anonymous) {
      headers = headers.delete('Authorization');
    }
    if (customHeaders) {
      customHeaders.keys().forEach(function (key) {
        const value = customHeaders.get(key);
        if (value !== '') {
          headers = headers.set(key, value);
        } else if (headers.has(key)) {
          headers = headers.delete(key);
        }
      });
    }
    return {headers: headers};
  }

  getToken() {
    let token = localStorage.getItem(Constants.TOKEN_KEY);
    if (!token) {
      token = '!JUNK!';
    }
    return token;
  }

  handleError(response: Response | any, message: string) {
    switch (response.status) {
      case Constants.ERR_UNAUTHORIZED:
        if (this.router.url === '/login') throw new ApiError(response.error.errors);
        this.router.navigate(['/login']);
        return EMPTY;
      case Constants.ERR_CANNOT_REACH_SERVER:
      case Constants.ERR_SERVICE_NOT_AVAILABLE:
      case Constants.ERR_GATEWAY_TIMED_OUT:
        return throwError(response.error);
      case Constants.ERR_BADREQUEST:
        throw new ApiError(response.error.errors);
      default:
        throw (response);
    }
  }
}
