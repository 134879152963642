import {Injectable} from '@angular/core';
import {ApiRequestInfrastructure} from '@pettly/services/apiRequestInfrastructure';

@Injectable({
  providedIn: 'root'
})
export class OEmbedService {
  constructor(private apiRequest: ApiRequestInfrastructure) {
  }

  public htmlFor(hyperlink: string): Promise<OEmbed> {
    const url = "https://iframe.ly/api/oembed?url=" + hyperlink + "&api_key=aba33d7d898e4204f38763&autoplay=1&mute=1&ssl=1";
    return this.apiRequest.get<OEmbed>(url);
  }
}

export interface OEmbed {
  url: string;
  type: string;
  version: string;
  title: string;
  author: string;
  author_url: string;
  provider_name: string;
  thumbnail_url: string;
  thumbnail_width: number;
  thumbnail_height: number;
  html: string;
}
