import {Injectable} from "@angular/core";
import {Constants} from "@pettly/shared/constants";
import {JwtHelperService} from "@auth0/angular-jwt";
import {TokenResponse} from "@pettly/models/TokenResponse";
import {AppNotInitializedError} from "@pettly/services/error/appNotInitiatializedError";

@Injectable()
export class AuthStorageService {
  public static readonly SOCIAL_KEY = 'social';

  constructor(private jwtHelper: JwtHelperService) {
  }

  getClientId() {
    return localStorage.getItem(Constants.CLIENT_ID_KEY);
  }

  setClientId(clientId: string) {
    localStorage.setItem(Constants.CLIENT_ID_KEY, clientId);
  }

  public getAuthToken() {
    return localStorage.getItem(Constants.TOKEN_KEY);
  }

  public getRefreshToken() {
    return localStorage.getItem(Constants.REFRESH_TOKEN_KEY);
  }

  public setToken(token: TokenResponse) {
    this.deleteToken();
    localStorage.setItem(Constants.TOKEN_KEY, token.token);
    localStorage.setItem(Constants.REFRESH_TOKEN_KEY, token.refreshToken);
    localStorage.setItem(Constants.CAREGIVER_ID_KEY, this.jwtHelper.decodeToken(token.token).sub);
  }

  public setSocialLoginProvider(provider: string) {
    localStorage.setItem(AuthStorageService.SOCIAL_KEY, provider);
  }

  public getSocialLoginProvider(): string {
    return localStorage.getItem(AuthStorageService.SOCIAL_KEY);
  }

  public deleteToken() {
    localStorage.removeItem(Constants.TOKEN_KEY);
    localStorage.removeItem(Constants.DEFAULT_PROFILE);
    localStorage.removeItem(Constants.REFRESH_TOKEN_KEY);
    localStorage.removeItem(AuthStorageService.SOCIAL_KEY);
    localStorage.removeItem(Constants.CAREGIVER_ID_KEY);
  }

  public async isTokenExpired(): Promise<boolean> {
    return this.jwtHelper.isTokenExpired();
  }

  public getCaregiverId(): string {
    const id = localStorage.getItem(Constants.CAREGIVER_ID_KEY);
    if (!id) {
      throw new AppNotInitializedError("Caregiver not found");
    }
    return id;
  }
}
