import {Component, Injector, NgZone, OnInit} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {StatusBar, Style} from "@capacitor/status-bar";
import {Capacitor} from "@capacitor/core";
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {GoogleService} from "@pettly/services/auth/providers/google.service";
import {FacebookService} from "@pettly/services/auth/providers/facebook.service";
import {createCustomElement} from "@angular/elements";
import {PopupComponent} from "@pettly/components/core/popup/popup.component";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = "Pettly";
  static pettlyUi = 'pui-';
  isHeaderVisible = true;

  constructor(private router: Router, private route: ActivatedRoute, private zone: NgZone,
              private googleService: GoogleService, private facebookService: FacebookService,
              private injector: Injector) {
    this.registerCustomComponents();
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        if (this.route.snapshot.firstChild.data.isHeaderVisible != undefined) {
          this.isHeaderVisible = this.route.firstChild.snapshot.data.isHeaderVisible;
        } else this.isHeaderVisible = true;
      });
  }

  registerCustomComponents() {
    const injector = this.injector;
    const PopupElement = createCustomElement(PopupComponent, {injector});
    customElements.define(AppComponent.pettlyUi + 'popup', PopupElement);
  }

  async ngOnInit(): Promise<void> {
    this.isHeaderVisible = false;
    await AppComponent.implementStatusOverlayForAndroid();
    await this.AddDeepLinkHandling();
    await this.googleService.initialize();
    await this.facebookService.initialize();
  }

  private async AddDeepLinkHandling() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(async () => {
        const slug = event.url.split(".io").pop();
        if (slug) {
          await this.router.navigateByUrl(slug);
        }
      });
    });
  }

  private static async implementStatusOverlayForAndroid() {
    if (Capacitor.getPlatform() !== 'android' || !Capacitor.isPluginAvailable('StatusBar')) return;
    await StatusBar.setStyle({style: Style.Light});
    await StatusBar.setOverlaysWebView({overlay: true});
  }
}
