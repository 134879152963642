import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@pettly/services/auth/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ForgotPasswordModel } from '@pettly/models/password';

@Component({
  selector: 'app-forgotpassword-element',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})

export class ForgotPasswordComponent implements OnInit {
  showWait = false;
  submitted = false;
  sendSuccessful = false;
  form: UntypedFormGroup;
  public get controls() { return this.form.controls; }
  baseUri: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  public async forgotPassword(): Promise<void> {
    try {
      this.submitted = true;
      if (this.form.invalid) {
        return;
      }

      this.showWait = true;
      await this.authService.forgotPassword(this.parseForgotPasswordFromControl());
      this.sendSuccessful = true;
    } catch (error) {
      alert(JSON.stringify(error));
    } finally {
      this.showWait = false;
    }
  }

  private parseForgotPasswordFromControl(): ForgotPasswordModel {
    const forgotPassword = new ForgotPasswordModel();
    forgotPassword.email = this.controls.email.value;
    forgotPassword.requestUri = this.baseUri;
    return forgotPassword;
  }
}
