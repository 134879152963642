<div class="post">
  <div class="title-box">
    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '50px', height: '50px' }"></ngx-skeleton-loader>
    <div class="d-flex flex-column">
      <ngx-skeleton-loader [theme]="{width: '100px',height: '15px','margin-bottom': '5px'}">
      </ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ width: '200px', height: '10px' }"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="content-box">
    <div class="image-container" style="height: 200px">
      <ngx-skeleton-loader
        [class]="'image_' + i"
        [theme]="{    'border-radius': '25px',width: '100%',height: '100%'}" *ngFor="let i of [0, 1, 2]">
      </ngx-skeleton-loader>
    </div>
  </div>
  <div class="interaction-box">
    <div class="interaction-icon-container">
      <ngx-skeleton-loader appearance="circle" [theme]="{ width: '20px', height: '20px' }"></ngx-skeleton-loader>
    </div>
    <div class="interaction-icon-container">
      <ngx-skeleton-loader appearance="circle" [theme]="{ width: '20px', height: '20px' }"></ngx-skeleton-loader>
    </div>
    <div class="interaction-icon-container">
      <ngx-skeleton-loader appearance="circle" [theme]="{ width: '20px', height: '20px' }"></ngx-skeleton-loader>
    </div>
    <ngx-skeleton-loader [theme]="{ width: '150px', height: '10px' }"></ngx-skeleton-loader>
  </div>
</div>
