import {Component, Input} from "@angular/core";
import {PetProfile} from "@pettly/models/petProfile";

@Component({
  selector: "userDetail-element",
  templateUrl: "./userDetail.component.html",
  styleUrls: ["./userDetail.component.scss"],
})
export class UserDetailComponent {
  @Input() activeProfile: PetProfile;
  @Input() profiles: PetProfile[];
}
