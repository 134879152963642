<!-- Friends activity -->
<!-- <div class="widget">
  <div class="widget-header">
    <h3 class="widget-caption">Trending now</h3>
  </div>
  <div class="widget-body bordered-top bordered-sky">
    <div class="card">
      <div class="content">
        <ul class="list-unstyled team-members">
          <li>
            <div class="row">
              <div class="col-3">
                <div class="avatar">
                  <img src="/assets/images/Friends/woman-2.jpg" alt="img"
                    class="rounded-circle img-no-padding img-fluid">
                </div>
              </div>
              <div class="col-9"> <b><a href="#">Hillary Markston</a></b> shared a <b><a href="#">publication</a></b>.
                <span class="timeago">5 min ago</span>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-3">
                <div class="avatar">
                  <img src="/assets/images/Friends/woman-3.jpg" alt="Circle Image"
                    class="rounded-circle img-no-padding img-fluid">
                </div>
              </div>
              <div class="col-9"> <b><a href="#">Leidy marshel</a></b> shared a <b><a href="#">publication</a></b>.
                <span class="timeago">5 min ago</span>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-3">
                <div class="avatar">
                  <img src="/assets/images/Friends/woman-4.jpg" alt="Circle Image"
                    class="rounded-circle img-no-padding img-fluid">
                </div>
              </div>
              <div class="col-9"> <b><a href="#">Presilla bo</a></b> shared a <b><a href="#">publication</a></b>.
                <span class="timeago">5 min ago</span>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-3">
                <div class="avatar">
                  <img src="/assets/images/Friends/woman-4.jpg" alt="Circle Image"
                    class="rounded-circle img-no-padding img-fluid">
                </div>
              </div>
              <div class="col-9"> <b><a href="#">Martha markguy</a></b> shared a <b><a href="#">publication</a></b>.
                <span class="timeago">5 min ago</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div> -->
<!-- End Friends activity -->
<!-- People You May Know -->
<!-- <div class="widget">
  <div class="widget-header">
    <h3 class="widget-caption">People You May Know</h3>
  </div>
  <div class="widget-body bordered-top bordered-sky">
    <div class="card">
      <div class="content">
        <ul class="list-unstyled team-members">
          <li>
            <div class="row">
              <div class="col-3">
                <div class="avatar">
                  <img src="/assets/images/Friends/guy-2.jpg" alt="Circle Image"
                    class="rounded-circle img-no-padding img-fluid">
                </div>
              </div>
              <div class="col-6">Carlos marthur</div>
              <div class="col-3 text-right">
                <button class="btn btn-sm btn-azure btn-icon"><i class="fa fa-user-plus"></i>
                </button>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-3">
                <div class="avatar">
                  <img src="/assets/images/Friends/woman-1.jpg" alt="Circle Image"
                    class="rounded-circle img-no-padding img-fluid">
                </div>
              </div>
              <div class="col-6">Maria gustami</div>
              <div class="col-3 text-right">
                <button class="btn btn-sm btn-azure btn-icon"><i class="fa fa-user-plus"></i>
                </button>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-3">
                <div class="avatar">
                  <img src="/assets/images/Friends/woman-2.jpg" alt="Circle Image"
                    class="rounded-circle img-no-padding img-fluid">
                </div>
              </div>
              <div class="col-6">Angellina mcblown</div>
              <div class="col-3 text-right">
                <button class="btn btn-sm btn-azure btn-icon"><i class="fa fa-user-plus"></i>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div> -->
<!-- End people yout may know -->

<div class="box">
  <div class="box-heading">
    <span>Trending Now</span>
  </div>
  <div *ngFor="let post of trendings; let i = index">
    <div class="item">
      <img
        class="rounded-circle mr-2"
        [src]="post.profile"
        alt="profile"
        width="40"
        height="40"
      />
      <div class="content">
        <div class="d-flex justify-content-between">
          <h6 class="m-0">{{ post.name }}</h6>
          <p class="m-0" style="color: #a5abbe;">{{ post.date }}</p>
        </div>
        <span>added a new photo</span>
        <div class="d-flex">
          <img
            [src]="image.url"
            alt="'Image_'+image.id"
            *ngFor="let image of post.images | slice: 0:2"
          />
        </div>
      </div>
    </div>
    <hr class="my-2" *ngIf="i + 1 !== trendings.length" />
  </div>
</div>
<div class="box mt-3">
  <div class="box-heading">
    <span>Pets You May Know</span>
  </div>
  <div *ngFor="let profile of profiles; let i = index">
    <div class="profiles">
      <div class="d-flex align-items-center">
        <img [src]="profile.img" alt="profile.name" />
        <div class="ml-2">
          <h5 class="m-0">{{ profile.name }}</h5>
          <small>{{ profile.breedName }}</small>
        </div>
      </div>
      <div>
        <button class="btn btn-outline-primary">Follow</button>
      </div>
    </div>
    <hr class="my-1" *ngIf="i + 1 !== profiles.length" />
  </div>
</div>
