import {ErrorHandler, Injectable} from "@angular/core";
import {AppNotInitializedError} from "@pettly/services/error/appNotInitiatializedError";
import {AuthService} from "@pettly/services/auth/auth.service";
import {Router} from "@angular/router";
import {ApiError} from "@pettly/services/error/apiError";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private authService: AuthService, private router: Router) {
  }

  async handleError(error): Promise<void> {
    error = this.unwrapError(error);
    if (error instanceof AppNotInitializedError) {
      console.log('AppNotInitializedError: ' + error.message);
      await this.logout();
    } else if (error instanceof TypeError) {
      // Suppress null reference, till the null propagation is added
    } else if (error instanceof ApiError) {
      error.errors.forEach((error) => console.log(error.code));
    } else {
      console.error('Unhandled exception occured.' + typeof error);
    }
  }

  private unwrapError(error) {
    if (error.promise && error.rejection) {
      error = error.rejection;
    }
    return error;
  }

  async logout(): Promise<void> {
    try {
      await this.authService.logout();
    } catch (e) {
      console.error(JSON.stringify(e));
    }
    await this.router.navigate(["/login"]);
  }

}
