<div class="edit-profile">
  <form (ngSubmit)="updateProfile()" class="form" [formGroup]="formGroup">
    <div>
      <input formControlName="name" type="text" class="form-control" placeholder="Full name of pet"/>
      <div
        *ngIf="(controls.name.touched || submitted) &&controls.name.errors" class="text-danger text-left">
        <small *ngIf="controls.name.errors.required">Name is required.</small>
      </div>
    </div>
    <div>
      <select class="form-control" (change)="onSelect()" formControlName="petGroup">
        <option selected disabled value="">Select a Group</option>
        <option *ngFor="let group of groups" [value]="group?.id">
          {{ group.name }}
        </option>
      </select>
      <div *ngIf="(controls.petGroup.touched || submitted) &&controls.petGroup.errors"
           class="text-danger text-left">
        <small *ngIf="controls.petGroup.errors">Pet group is required.</small>
      </div>
    </div>
    <div>
      <select class="form-control" formControlName="petBreed">
        <option selected disabled value="">Select a breed</option>
        <option *ngFor="let breed of breeds" [value]="breed?.id">
          {{ breed.name }}
        </option>
      </select>
      <div *ngIf="(controls.petBreed.dirty || submitted) && controls.petBreed.errors"
           class="text-danger text-left">
        <small *ngIf="controls.petBreed.errors">Pet breed is required.</small>
      </div>
    </div>
    <div>
      <input formControlName="dateOfBirth" type="date" class="form-control" placeholder="Date of birth"/>
      <div *ngIf="(controls.dateOfBirth.touched || submitted) && controls.dateOfBirth.errors"
           class="text-danger text-left">
        <small *ngIf="controls.dateOfBirth.errors.required">Date of birth is required.</small>
      </div>
    </div>
    <div>
      <div class="form-check-group">
        <label class="from-inline control-left mr-sm-2">Gender:</label>
        <div *ngFor="let gender of genders;let i=index" class="form-check form-check-inline">
          <input formControlName="gender" type="radio" class="form-inline" placeholder="Gender" [value]="gender.value"
                 id="option{{i}}"/>
          <label class="form-check-label" for="option{{i}}">{{gender}}</label>
        </div>
      </div>
      <div *ngIf="(controls.gender.touched || submitted) && controls.gender.errors" class="text-danger text-left">
        <small *ngIf="controls.gender.errors">Gender is required.</small>
      </div>
    </div>
    <div class="text-center profile-pic">
      <img [src]="imageSrc" class="img-fluid img-thumbnail"/>
    </div>
    <div class="caption">
      <label for="profilePicture" class="btn btn-outline-primary mt-2 btn-block" role="button">
        Select your Picture
        <input type="file" id="profilePicture" accept="image/*" #profilePicture (change)="readUrl($event)"
               hidden/>
      </label>
    </div>
    <div>
      <input formControlName="description" type="text" class="form-control"
             placeholder="Optional Description of your pet"/>
    </div>
    <button type="submit" class="btn btn-primary">
      <i *ngIf="showWait" class="fa fa-paw fa-spin"></i>Save changes
    </button>
  </form>
</div>
