import {EventEmitter, Injectable} from "@angular/core";
import {API_URL} from "@pettly/shared/API_URL";
import {ApiRequestInfrastructure} from "../apiRequestInfrastructure";
import {User} from "@pettly/models/user";
import {ForgotPasswordModel, ResetPasswordModel} from "@pettly/models/password";
import {TokenResponse} from "@pettly/models/TokenResponse";
import {environment} from "environments/environment";
import {AuthStorageService} from "@pettly/services/auth/auth-storage.service";
import {GoogleService} from "@pettly/services/auth/providers/google.service";
import {FacebookService} from "@pettly/services/auth/providers/facebook.service";
import {SocialAuthProvider} from "@pettly/services/auth/sociaAuthProvider";

@Injectable({providedIn: 'root',})
export class AuthService {
  public userChanged = new EventEmitter<string>();
  public socialAuthProviders;

  constructor(
    private apiRequest: ApiRequestInfrastructure,
    private storageService: AuthStorageService,
    private window: Window,
    googleAuthService: GoogleService,
    facebookAuthService: FacebookService,) {
    this.socialAuthProviders = {
      facebook: facebookAuthService,
      google: googleAuthService
    }
  }

  public async login(username: string, password: string): Promise<void> {
    const body = {
      username: username,
      password: password,
      clientId: this.storageService.getClientId(),
    };
    const data = await this.apiRequest.post<TokenResponse>(API_URL.ACCOUNT_TOKEN, body, true)
    this.storageService.setToken(data);
    this.userChanged.emit(this.storageService.getCaregiverId());
  }

  public refreshAuthToken(): Promise<TokenResponse> {
    const body = {
      clientId: this.storageService.getClientId(),
      accessToken: this.storageService.getAuthToken(),
      refreshToken: this.storageService.getRefreshToken(),
    };
    return this.apiRequest.post<TokenResponse>(API_URL.ACCOUNT_TOKEN_REFRESH, body, true);
  }

  public register(body: User): Promise<void> {
    this.userChanged.emit();
    const url = API_URL.ACCOUNT_REGISTER.toLowerCase();
    return this.apiRequest.post<void>(url, body);
  }

  public async onSocialLogin(token: string, provider: string): Promise<void> {
    let data = await this.loginWithSocialToken(token, provider)
    if (data) {
      this.storageService.setToken(data);
      this.storageService.setSocialLoginProvider(provider);
      this.userChanged.emit(this.storageService.getCaregiverId());
    }
  }

  private loginWithSocialToken(token: string, provider: string): Promise<TokenResponse> {
    const url = `${environment.API_URL}api/identity/accounts/token/social/${provider.toLowerCase()}?token=${token}&clientId=${this.storageService.getClientId()}`;
    return this.apiRequest.post<TokenResponse>(url, null);
  }

  public forgotPassword(forgotPasswordModel: ForgotPasswordModel): Promise<void> {
    const url = API_URL.ACCOUNT_FORGOT_PASSWORD;
    forgotPasswordModel.requestUri = this.window.location.host + "/reset-password";
    return this.apiRequest.post(url, forgotPasswordModel);
  }

  public resetPassword(resetPasswordModel: ResetPasswordModel): Promise<void> {
    const url = `${API_URL.ACCOUNT_RESET_PASSWORD}`;
    return this.apiRequest.post(url, resetPasswordModel);
  }

  public isAuthenticated(): Promise<boolean> {
    return this.storageService.isTokenExpired();
  }

  async logout(): Promise<void> {
    try {
      const provider = this.storageService.getSocialLoginProvider();
      if (provider) await this.signOutSocialLogin(provider);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
    this.storageService.deleteToken();
    this.userChanged.emit();
  }

  public confirmEmail(email: string, code: string): Promise<void> {
    const url = `${API_URL.ACCOUNT_EMAIL_CONFIRM}`;
    return this.apiRequest.post(url, {email, code});
  }

  private async signOutSocialLogin(provider: string) {
    const service = this.socialAuthProviders[provider.toLowerCase()] as SocialAuthProvider;
    if (service) await service.signOut();
  }

  public getCaregiverId(): string {
    return this.storageService.getCaregiverId();
  }
}
