<div class="comment-box">
  <div *ngFor="let comment of comments" class="comment">
    <img [fallbackSrc]="fallBackProfilePicture" [src]="comment.author.profilePictureUri" class="author-dp"/>
    <div class="content">
      <span class="username"><strong>{{ comment.author.name }}</strong></span>
      <span class="comment-text">{{ comment.content }}</span>
      <span class="date">{{ comment.createdAt | retrospectiveShort }}</span>
    </div>
    <div class="interaction-icon-container">
      <img (click)="sendReactionOnComment(comment)" [class.active]="hasMyReaction(comment)" alt="paw"
           class="interaction-icon" src="assets/icons/paw.svg"/>
      <span>{{ comment.reactions.length|countPipe }}</span>
    </div>
  </div>
  <form #createPostForm="ngForm" (ngSubmit)="sendComment()" action="#" class="comment-draft" method="post"
        name="addCommentForm">
    <div class="add-comment">
      <img [fallbackSrc]="fallBackProfilePicture" [src]="activeProfile.profilePictureLocation" class="author-dp"/>
      <input #content (focus)="onFocus()" [(ngModel)]="commentContent" class="form-control form-control-sm"
             name="content" placeholder="Write your Comments" type="text"/>
      <div class="interaction-icon position-relative">
        <i (click)="toggleEmojiPicker()" class="far fa-grin-alt"></i>
        <emoji-mart (emojiSelect)="addEmoji($event)" *ngIf="showEmojiPicker"
                    class="emoji-mart position-absolute bottom-0 end-0 " [darkMode]="false"
                    [showSingleCategory] set="twitter">
        </emoji-mart>
      </div>
    </div>
    <button class="btn btn-sm btn-primary" type="submit"><i class="fa fa-paper-plane"></i></button>
  </form>
</div>
