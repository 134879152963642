import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '@pettly/services/auth/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ConfirmedValidator } from 'app/confirmed.validator';
import { ResetPasswordModel } from '@pettly/models/password';

@Component({
  selector: 'app-resetpassword-element',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  showWait = false;
  submitted = false;
  sendSuccessful = false;
  resetPasswordForm: UntypedFormGroup;
  emailParameter: string;
  codeParameter: string;
  public get controls() { return this.resetPasswordForm.controls; }

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validators: ConfirmedValidator('password', 'confirmPassword')
    });


    this.route.queryParamMap.subscribe((param) => {
      this.emailParameter = param.get('email');
      this.codeParameter = param.get('code');
    });
  }

  public async resetPassword(): Promise<void> {
    try {
      this.submitted = true;
      if (this.resetPasswordForm.invalid) {
        return;
      }
      this.showWait = true;
      await this.authService.resetPassword(this.parseResetPasswordFormControl());
      await this.router.navigate(['/resetpasswordconfirmation']);
    } catch (error) {
      alert(JSON.stringify(error));
    } finally {
      this.showWait = false;
    }

  }

  private parseResetPasswordFormControl(): ResetPasswordModel {
    const resetPasswordModel = new ResetPasswordModel();
    resetPasswordModel.email = this.emailParameter;
    resetPasswordModel.code = this.codeParameter;
    resetPasswordModel.password = this.controls.password.value;
    return resetPasswordModel;
  }
}
