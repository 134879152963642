export class ApiError extends Error {
  errors: Array<ApiErrorResponse>;

  constructor(data: Array<ApiErrorResponse>) {
    super('BadRequest');
    Object.setPrototypeOf(this, ApiError.prototype);
    this.name = 'ApiError';
    this.errors = data;
  }
}

export interface ApiErrorResponse {
  code: string;
  description: string;
}
