<form class="search-box" role="search" (ngSubmit)="search()"
      [ngStyle]="{ width: path.includes('search') ? '100%' : 'auto' }">
  <a routerLink="." (click)="search()">
    <img src="assets/icons/search.svg" width="20" height="20"/>
  </a>
  <input
    [(ngModel)]="query"
    type="text"
    class="form-control"
    placeholder="Search pet"
    name="q"
    [(ngModel)]="searchParam"
  />
</form>
