import {Pipe, PipeTransform} from '@angular/core';
import {Genders} from '@pettly/models/petProfile';

@Pipe({
  name: 'genderLiteral'
})
export class GenderPipe implements PipeTransform {
  transform(value: number, args?: any): string {
    let output = Genders.getGenderFromValue(value).toString();
    if (output === 'Not Known' || output === 'Not Applicable') {
      output = 'Gender ' + output.toLowerCase();
    }
    return output;
  }
}


