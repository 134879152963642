import {Capacitor} from "@capacitor/core";

export abstract class SocialAuthProvider {
  public abstract readonly providerName: string;
  private initialized = false;

  protected abstract initializeImpl(): Promise<void>;

  public abstract signIn(): Promise<SocialLoginResult>;

  public abstract signOut(): Promise<void>

  protected constructor() {
    if (Capacitor.isNativePlatform()) this.initialized = true;
  }

  public initialize(): Promise<void> {
    if (this.initialized) return Promise.resolve();
    return this.initializeImpl().then(() => {
      this.initialized = true;
    });
  }
}

export interface SocialLoginResult {
  token: string;
}
