import {effect, Injectable, signal, WritableSignal} from '@angular/core';
import {ApiRequestInfrastructure} from '../apiRequestInfrastructure';
import {API_URL} from '@pettly/shared/API_URL';
import {SignalrService} from "@pettly/services/signalr.service";

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  public newNotifications: WritableSignal<Notification[]> = signal<Notification[]>([]);

  constructor(private apiRequest: ApiRequestInfrastructure,
              private signalrService: SignalrService) {
    effect(() => {
      if (this.signalrService.connected()) {
        this.signalrService.listenToFeed("ReceiveNewNotification", (petId, data: Notification) => {
          console.log('message received')
          this.newNotifications.update(notifications => [...notifications,data]);
        });
      }
    });
  }

  public getNotifications(petId: string, pageNumber: number, pageSize: number): Promise<Notification[]> {
    const url = `${API_URL.notifications({petId})}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.apiRequest.get<Notifications>(url);
  }

}

export interface Notification {
  id: string;
  actors: Pet[];
  action: string;
  target: Pet;
  targetActivity: string;
  publishedAt: Date;
  isRead: boolean;
  isSeen: boolean;
}


export class Notifications extends Array<Notification> {
  unRead: number;
  unSeen: number;
}


export interface Pet {
  id: string;
  name: string;
  profilePictureUri: string;
}
