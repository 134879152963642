import {Injectable} from '@angular/core';
import {SocialAuthProvider, SocialLoginResult} from "@pettly/services/auth/sociaAuthProvider";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";

@Injectable({
  providedIn: 'root'
})
export class GoogleService extends SocialAuthProvider {
  readonly providerName = "GOOGLE";

  protected initializeImpl() {
    GoogleAuth.initialize({
      clientId: "186966789478-ingfmdlc1jo11n0g2903p75jcro18igm.apps.googleusercontent.com"
    });
    return Promise.resolve();
  }

  async signIn(): Promise<SocialLoginResult> {
    let googleUser = await GoogleAuth.signIn();
    return {token: googleUser.authentication.idToken};
  }

  async signOut(): Promise<void> {
    return GoogleAuth.signOut()
  }
}
