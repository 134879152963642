import {Injectable} from '@angular/core';
import {NgElement, WithProperties} from '@angular/elements';
import {PopupComponent} from './popup.component';


@Injectable()
export class PopupService {

  constructor() {
  }

  showAsElement(node: Node) {
    const popupEl: NgElement & WithProperties<PopupComponent> = document.createElement('pui-popup') as any;
    popupEl.appendChild(node);
    popupEl.addEventListener('closed', () => document.body.removeChild(popupEl));
    document.body.appendChild(popupEl);
  }
}
