<div class="container page-content">
  <div class="col">
    <div class="row">
      <div class="col">
        <h4 *ngIf="!searchParam" class=" text ">Do you know these pets?</h4>
        <h4 *ngIf="searchParam" class=" text ">Here is what we found?</h4>
        <h6 class="row text ml-3">Follow them to see their updates.</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4" *ngFor="let profile of profiles">
        <div class="box">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <img class="rounded-circle mr-3" [src]="profile.profilePictureUri || fallBackProfilePicture" width="40"
                   height="40"/>
              <div class="profile">
                <h6 class="m-0 text-large" style="cursor: pointer"
                    [routerLink]="['/profile', profile.id]">{{ profile.name }}</h6>
                <small>{{ profile.breed }}</small>
              </div>
            </div>
            <div>
              <button class="btn btn-outline-primary" (click)="follow(profile.id)">Send Friend request</button>
            </div>
          </div>
          <div class="profile-details">
            <div class="item">
              <button>
                <img src="assets/icons/location-pin.svg" alt="location"/>
              </button>
              <span>Lives with John Nash in Miami, Florida</span>
            </div>
            <div class="item">
              <button><img src="assets/icons/gender.svg" alt="gender"/></button>
              <span> {{ profile.gender | genderLiteral }}</span>
            </div>
          </div>
          <div>
            <span class="grey-text">Common Friends</span>
            <div class="d-flex flex-wrap">
              <img class="rounded-circle m-1" src="assets/images/Friends/guy-5.jpg" width="40" height="40"
                   alt="friend1"/>
              <img class="rounded-circle m-1" src="assets/images/Friends/guy-6.jpg" width="40" height="40"
                   alt="friend1"/>
              <img class="rounded-circle m-1" src="assets/images/Friends/guy-7.jpg" width="40" height="40"
                   alt="friend1"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
