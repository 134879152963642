import { Component, Input } from '@angular/core';
import { PetProfile, Gender } from '@pettly/models/petProfile';
import { Constants } from '@pettly/shared/constants';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-profile-detail',
  templateUrl: 'profile-detail.component.html',
  styleUrls: ['profile-detail.component.scss']
})
export class ProfileDetailComponent {
  format: string;
  @Input() activeProfile: PetProfile;
  @Input() isOwnProfile: boolean;

  constructor(
    private modalService: NgbModal
  ) {
    this.format = Constants.DateFormat;
  }

  get gender(): Gender {
    return PetProfile.formattedGender(this.activeProfile.gender);
  }
}
