export class DraftContent {
  private readonly _domainPrefixes = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?[^\s]*)?/gi;
  private readonly _wellKnowDomainSuffixes = [/\.com/, /\.de/, /\.org/, /\.net/, /\.us/, /\.co/, /\.edu/, /\.gov/, /\.biz/, /\.za/,
    /\.info/, /\.cc/, /\.ca/, /\.cn/, /\.fr/, /\.ch/, /\.au/, /\.in/, /\.jp/, /\.be/, /\.it/,
    /\.nl/, /\.uk/, /\.mx/, /\.no/, /\.ru/, /\.br/, /\.se/, /\.es/, /\.at/, /\.dk/, /\.eu/, /\.il/];

  private readonly _content: string;
  private readonly _hyperLinks: string[];
  public toString = (): string => this._content;

  public get hyperlinks(): string[] { return this._hyperLinks; }
  public containsHyperlinks(): boolean { return this._hyperLinks?.length > 0; }

  public isEmpty(): boolean { return (!this._content) || this._content.toString().trim() == ''; }

  public constructor(content: string) {
    this._content = content;
    this._hyperLinks = this.extractHyperlinks();
  }

  private extractHyperlinks(): string[] {
    const regex = new RegExp(this._domainPrefixes);
    let match: RegExpExecArray;
    const splitText: string[] = [];
    let domainMatch: RegExpMatchArray;
    let urlLength = 0;
    let abort = false;

    while ((match = regex.exec(this._content)) != null) {
      abort = true;
      this._wellKnowDomainSuffixes.forEach(wellKnowDomainSuffix => {
        domainMatch = match[0].match(wellKnowDomainSuffix);
        if (domainMatch) {
          urlLength = domainMatch[0].length + domainMatch.index;
          //we	found	one	of	the	domains	we	look	for	- now	we	need	to	know	if	the	.com	or	.de	etc.	is	at	the	VERY	END	of	the	domain
          if (match[0].length == urlLength) abort = false;
          if (match[0].length > urlLength) {
            if (match[0].substr(urlLength, 1) == '/') abort = false;
          }
        }
      });

      abort = this.isEmail(match, this._content, abort);

      //we	want	to	avoid	matching	?	without	anything	at	the	end	like	www.epiloge.com/@axel-wittmann?
      if ((match.index != 0) && (this._content[match.index + match[0].length - 1] == '?')) {
        match[0] = match[0].substr(0, match[0].length - 1);
      }
      if (!abort) {
        let cleanedLink = this._content.substr(match.index, (match[0].length));
        cleanedLink = cleanedLink.replace(/^https?:\/\//, '');
        splitText.push(cleanedLink);
      }

    }

    return splitText;
  }

  private isEmail(match: RegExpExecArray, textToCheck: string, abort: boolean): boolean {
    if ((match.index != 0) && (textToCheck[match.index - 1] == '@')) {
      abort = true;
    }
    return abort;
  }
}
