import {Component, OnInit, Signal} from "@angular/core";
import {PetProfile} from "@pettly/models/petProfile";
import {Router} from "@angular/router";
import {PetProfileStorageService} from "@pettly/services/profile/pet-profile-storage.service";

@Component({
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  public get activeProfile(): Signal<PetProfile> {
    return this.profileStore.activeProfile;
  }

  public get profiles(): Signal<PetProfile[]> {
    return this.profileStore.petProfiles;
  }

  constructor(
    private profileStore: PetProfileStorageService,
    private router: Router
  ) {
  }

}
