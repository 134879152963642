<!-- post state form -->
<form class="box" (ngSubmit)="sendPost()" #createPostForm="ngForm" name="createPostForm">
  <h6 class="text-xlarge">Add post</h6>
  <textarea class="form-control" rows="6" placeholder="Whats in your mind today?" name="content"
            [(ngModel)]="draftPost.content" required minlength="5"></textarea>
  <div class="box-footer box-form">
    <div class="d-flex">
      <button class="footer-icon" (click)="fileInput.click()">
        <img src="assets/icons/image-plus.svg" width="20" height="20"/>
      </button>
      <button class="footer-icon" (click)="fileInput.click()">
        <img src="assets/icons/video.svg" width="20" height="20"/>
      </button>
      <button class="footer-icon">
        <img src="assets/icons/location.svg" width="20" height="20"/>
      </button>
      <button class="footer-icon">
        <img src="assets/icons/mic.svg" width="20" height="20"/>
      </button>
    </div>
    <input
      type="file"
      id="postMedia"
      name="postMedia"
      class="input-file"
      (change)="readUrl($event)"
      accept="image/*,video/*"
      #fileInput
    />
    <button
      type="submit"
      class="btn btn-primary btn-post"
      [disabled]="showWait"
    >
      <i *ngIf="showWait" class="fa fa-paw fa-spin"></i>Add Post
    </button>
  </div>
  <div [ngSwitch]="draftPost.previewFrom">
    <img
      *ngSwitchCase="PreviewFromTypes.Image"
      class="img-fluid"
      src="{{ draftPost.mediaPreview }}"
      alt="Photo"
    />
    <div
      *ngSwitchCase="PreviewFromTypes.Link"
      class="img-fluid"
      bind-innerHTML="draftPost.mediaPreview | safeHtml"
      alt="Photo"
    ></div>
    <i
      *ngIf="draftPost.mediaPreview"
      class="fas fa-times overlay"
      (click)="draftPost.removeMedia()"
    ></i>
  </div>
</form>
