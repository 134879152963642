import {Component, computed, OnInit, signal, Signal} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {PetCatalogService} from "@pettly/services/catalog/petCatalog.service";
import {PetProfile} from "@pettly/models/petProfile";
import {Constants} from "@pettly/shared/constants";
import {SharedUiService} from "@pettly/services/ui/sharedUi.service";
import {AuthService} from "@pettly/services/auth/auth.service";
import {PetProfileStorageService} from "@pettly/services/profile/pet-profile-storage.service";

@Component({
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  public showProfile = true;
  public selectedProfile: Signal<PetProfile>;

  public get profiles(): Signal<PetProfile[]> {
    return this.profileStore.petProfiles;
  }

  public get isOwnProfile() {
    return this.selectedProfile().id === this.profileStore.activeProfile().id;
  }

  public get fallbackProfilePicture(): string {
    return Constants.DEFAULT_PROFILE_PICTURE;
  }

  constructor(
    private profileStore: PetProfileStorageService,
    private petCatalogService: PetCatalogService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedUiService: SharedUiService,
    private authService: AuthService
  ) {
    this.sharedUiService.toggleProfileSettings.subscribe(() => {
      this.showProfile = !this.showProfile;
    });
  }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");
    if (id) {
      const profile = await this.petCatalogService.getPetProfileById(id)
      this.selectedProfile = signal(profile);
    } else {
      this.selectedProfile = this.profileStore.activeProfile;
    }
  }

  public showProfileDetails(): void {
    this.showProfile = true;
  }

  async logoutClicked(): Promise<void> {
    await this.authService.logout();
    await this.router.navigate(["/login"]);
  }
}
