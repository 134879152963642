export class Constants {
  // Cookie keys
  public static readonly TOKEN_KEY = 'token';
  public static readonly CAREGIVER_ID_KEY = 'caregiverId';
  public static readonly REFRESH_TOKEN_KEY = 'refreshtoken';
  public static readonly CLIENT_ID_KEY = 'clientid';
  public static readonly DEFAULT_PROFILE = 'defaultProfile';

  // Server Errors
  public static readonly ERR_UNAUTHORIZED = 401;
  public static readonly ERR_INTERNAL_SERVER = 500;
  public static readonly ERR_SERVICE_NOT_AVAILABLE = 503;
  public static readonly ERR_GATEWAY_TIMED_OUT = 504;
  public static readonly ERR_CANNOT_REACH_SERVER = 0;
  public static readonly ERR_FORBIDDEN = 403;
  public static readonly ERR_BADREQUEST = 400;
  public static readonly CREATED = 200;
  public static readonly CREATEDAT = 201;
  public static readonly UPDATED = 204;
  public static readonly OK = 200;
  public static readonly EXPIREDTOKEN = 498;
  public static readonly NOCONTENT = 204;
  public static readonly WEAK_PASSWORD = 202;

  // Header literals
  public static readonly BEARER = 'Bearer ';
  public static readonly AUTHORIZATION_KEY = 'Authorization';


  public static readonly DateFormat = 'MMMM d, y';
  public static readonly DateFormatWithinSameYear = 'MMMM d';
  public static readonly DateFormatWithinSameWeek = 'EEEE h:mm a';
  public static readonly DateFormatWithin24Hrs = 'h:mm a';

  // Default Asset Values
  public static readonly DEFAULT_PROFILE_PICTURE = 'assets/images/Profile/default-avatar-cat2.png';
}
